import React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components';
import CardVideo from './CardVideo';
import useVideosRow from './hooks';

interface Props {
  courseId: string;
  courseTitle: string;
  classId: string;
}

const VideosRow = ({ courseId, courseTitle, classId }: Props) => {
  const {
    colors,
    videos,
    margin,
    handleNavigationLeft,
    handleNavigationRight,
  } = useVideosRow({
    courseId,
  });
  return (
    <Container className="videos-row" margin={margin}>
      <LeftArrow className="arrow" onClick={handleNavigationLeft}>
        <ButtonWrapper>
          <MdKeyboardArrowLeft size={56} color={colors.primary} />
        </ButtonWrapper>
      </LeftArrow>
      {videos.map(video => (
        <CardVideo
          video={video}
          key={video.id}
          classId={classId}
          courseId={courseId}
          courseTitle={courseTitle}
          isLast={videos.length === videos.indexOf(video) + 1}
        />
      ))}
      <RightArrow className="arrow">
        <ButtonWrapper onClick={handleNavigationRight}>
          <MdKeyboardArrowRight size={56} color={colors.primary} />
        </ButtonWrapper>
      </RightArrow>
    </Container>
  );
};

const Container = styled.div<{ margin: number }>`
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 0.1rem 0;
  transition: all 0.3s ease-in-out;
  margin-left: ${({ margin }) => margin}px;

  @media screen and (max-width: 768px) {
    & .arrow {
      opacity: 1;
    }
  }
`;

const ButtonWrapper = styled.div`
  padding: 0.2rem;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityIcon};
  }
`;

const LeftArrow = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 5rem;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;

const RightArrow = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 5rem;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
`;

export default VideosRow;
