import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useLessonAnalytics, {
  VideoWatchTime,
} from '../../hooks/data/useLessonAnalytics';
import useLessons from '../../hooks/data/useLessons';
import CourseVideo from '../../models/CourseVideo';
import {
  LessonEvents,
  VideoWatchTimeAnalyticsReportData,
} from '../../models/LessonAnalyticsData';
import api from '../../services/api';
import useAuth from '../../hooks/useAuth';
import { Quiz } from '../../models/Quiz';
import useQuiz from '../../hooks/data/useQuiz';

const useVideoView = (
  video: CourseVideo,
  courseName: string,
  courseId?: string,
) => {
  const { user } = useAuth();
  const targetVideo = video.id;
  const { updateVideoWatchTime } = useLessonAnalytics(video.id);
  const { updateLesson, lessons } = useLessons(courseId);
  const { quizzes } = useQuiz();
  const history = useHistory();

  // TODO: Refatorar passando para useLessonAnalytics sem quebrar
  const [lessonWatchTime, setLessonWatchTime] = useState<VideoWatchTime>(
    {} as VideoWatchTime,
  );

  const requestLessonAnalytics = useCallback(async () => {
    const { data } = await api.get(
      `/lesson-analytics/lesson-watch-time/${user.id}/${targetVideo}`,
    );
    setLessonWatchTime(
      (data as VideoWatchTime) ||
        ({ currentPlayerTime: 0, totalWatchTime: 0 } as VideoWatchTime),
    );
  }, [targetVideo, user.id]);

  const reportVideoDuration = useCallback(
    (duration: number) => {
      updateLesson({ id: video.id, duration });
    },
    [video.id, updateLesson],
  );

  const reportWatchTime = useCallback(
    (analyticsData: VideoWatchTimeAnalyticsReportData) => {
      updateVideoWatchTime({
        totalWatchTime: analyticsData.totalWatchTime,
        currentPlayerTime: analyticsData.currentPlayerTime,
      });
    },
    [updateVideoWatchTime],
  );

  const handleClickVideo = (lesson: CourseVideo) => {
    history.push({
      pathname: '/video',
      state: {
        ...(history.location.state || {}),
        courseId,
        courseName,
        video: lesson,
      },
    });
  };
  const handleClickQuiz = (quiz: Quiz) => {
    history.push({
      pathname: '/video',
      state: {
        ...(history.location.state || {}),
        video,
        quiz,
        courseId,
        courseName,
      },
    });
  };

  useEffect(() => {
    requestLessonAnalytics();
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    lessons,
    quizzes,
    targetVideo,
    lessonWatchTime,
    reportWatchTime,
    handleClickQuiz,
    handleClickVideo,
    reportVideoDuration,
  };
};

export default useVideoView;
