import React from 'react';
import styled from 'styled-components';
import { FaRegTrashAlt } from 'react-icons/fa';
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface CommentProps {
  commentId: string;
  content: string;
  onDeleteComment: (id: string, studentId: string) => void;
  studentName: string;
  userId: string;
  studentId: string;
  avatarUrl: string;
  publishedAt: Date;
}

export function Comment({
  commentId,
  content,
  onDeleteComment,
  studentName,
  userId,
  studentId,
  avatarUrl,
  publishedAt,
}: CommentProps) {
  function handleDeleteComment(id: string) {
    onDeleteComment(id, userId);
  }

  const publishedDateFormatted = format(
    publishedAt,
    "d 'de' LLLL 'às' HH:mm'h'",
    {
      locale: ptBR,
    },
  );
  const publishedDateRelativeToNow = formatDistanceToNow(publishedAt, {
    locale: ptBR,
    addSuffix: true,
  });
  return (
    <CommentContainer>
      <Avatar>
        <img src={avatarUrl} alt="Avatar do usuário" />
      </Avatar>
      <CommentBox>
        <CommentContent>
          <Header>
            <AuthorAndTime>
              <strong>{studentName}</strong>
              <time
                title={publishedDateFormatted}
                dateTime={publishedAt.toString()}
              >
                {publishedDateRelativeToNow}
              </time>
            </AuthorAndTime>
            {userId === studentId && (
              <button
                type="button"
                onClick={() => handleDeleteComment(commentId)}
                title="Deletar comentário"
                aria-label="Deletar comentário"
              >
                <FaRegTrashAlt size={16} />
              </button>
            )}
          </Header>
          <p>{content}</p>
        </CommentContent>
      </CommentBox>
    </CommentContainer>
  );
}
const CommentContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 2rem;
`;
const Avatar = styled.div`
  max-width: 10%;
  display: flex;
  justify-content: center;
  & img {
    width: 3rem;
    height: 3rem;
    border-radius: 8px;
  }
`;

const CommentBox = styled.div`
  flex: 1;
  min-width: 5rem;
`;
const CommentContent = styled.div`
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem;
  & p {
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.themedTitle};
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & button {
    background: transparent;
    border: none;
    font-size: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
  }
  & button:hover {
    color: #f75a68;
  }
`;

const AuthorAndTime = styled.div`
  & strong {
    display: block;
    font-size: 0.875rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.themedTitle};
  }
  & time {
    display: block;
    font-size: 0.75rem;
    line-height: 1.6;
    color: #8d8d99;
  }
`;
