import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import GenericRanking from '../../components/GenericRanking';
import useRankingPage from './hooks';
import useSkeleton from '../../components/Skeleton/hooks';
import { RankingSkeleton } from '../../components/Skeleton';

const RankingPage = () => {
  const ClassRanking = GenericRanking;
  const { ranking } = useRankingPage();
  const { isLoading, setSkeletonLoading } = useSkeleton();

  useEffect(() => {
    if (ranking.length > 1) setSkeletonLoading(false);
  }, [ranking]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Header />
      <Wrapper>
        <Title>Ranking de Turmas</Title>
        {isLoading ? (
          <RankingSkeleton isLoading={isLoading} size={5} />
        ) : (
          <ClassRanking ranking={ranking} />
        )}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Wrapper = styled.div`
  z-index: 1;
  padding: calc(10vh) 0 0 0;
  max-width: 920px;
  margin: 0 auto;
  padding: 10vh 1rem 0;
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.themedTitle};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2.5rem;
  & p {
    margin: 0 1rem;

    &:nth-child(-n + 5) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    & p {
      margin: 0 0.5rem;
    }
  }
`;

export default RankingPage;
