import React from 'react';
import styled from 'styled-components';
import ClassesContainer from '../../components/ClassesContainer';
import Header from '../../components/Header';
import HeroImage from './HeroImage';

const HomePage = () => {
  return (
    <Container>
      <Header />
      <Wrapper>
        <HeroImage isDebut />
        <ClassesContainer />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Wrapper = styled.div`
  z-index: 1;
  padding-top: 10vh;
`;

export default HomePage;
