import React, { useEffect, useRef, useState } from 'react';
import YouTube, { YouTubeEvent } from 'react-youtube';
import { VideoWatchTimeAnalyticsReportData } from '../../models/LessonAnalyticsData';
import extractYouTubeVideoId from '../../utils/extractYouTubeVideoId';
import { VideoWatchTime } from '../../hooks/data/useLessonAnalytics';

type ComponentProps = {
  videoUrl: string;
  lessonId: string;
  lessonWatchTime: VideoWatchTime;
  handlePlayerReady: () => void;
  reportWatchTime: (watchTimeReport: VideoWatchTimeAnalyticsReportData) => void;
  /**
   * Temporary solution to collect video durations.
   */
  reportVideoDuration: (duration: number) => void;
};

const YouTubePlayerWithAnalyticsReport = ({
  videoUrl,
  lessonId,
  reportWatchTime,
  lessonWatchTime,
  handlePlayerReady,
  reportVideoDuration,
}: ComponentProps) => {
  const videoId = extractYouTubeVideoId(videoUrl) || '';
  const currentInterval = useRef<number>(0);

  const [an, setAn] = useState<
    Record<string, VideoWatchTimeAnalyticsReportData>
  >({});

  const ready = () => {
    handlePlayerReady();
  };

  useEffect(() => {
    if (lessonWatchTime.totalWatchTime >= 0) {
      setAn(prevState => ({
        ...prevState,
        [lessonId]: {
          currentPlayerTime: lessonWatchTime.currentPlayerTime,
          totalWatchTime: 0,
        },
      }));
    } else {
      setAn(prevState => ({
        ...prevState,
        [lessonId]: {
          currentPlayerTime: 0,
          totalWatchTime: 0,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId, reportWatchTime, lessonWatchTime]);

  const handlePlayerStateChangeEvent = (e: YouTubeEvent) => {
    if (e.data === YouTube.PlayerState.UNSTARTED) {
      const videoDuration = e.target.getDuration();
      reportVideoDuration(videoDuration);
    } else if (e.data === YouTube.PlayerState.PLAYING) {
      const time = Math.floor(e.target.getCurrentTime());
      an[lessonId].currentPlayerTime = time;
      currentInterval.current = window.setInterval(() => {
        an[lessonId].totalWatchTime += 1;
        an[lessonId].currentPlayerTime += 1;
      }, 1000);
    } else if (e.data === YouTube.PlayerState.PAUSED) {
      window.clearInterval(currentInterval.current);
      reportWatchTime({
        currentPlayerTime: an[lessonId].currentPlayerTime,
        totalWatchTime: an[lessonId].totalWatchTime,
      });
      an[lessonId].totalWatchTime = 0;
    } else if (e.data === YouTube.PlayerState.ENDED) {
      reportWatchTime({
        currentPlayerTime: an[lessonId].currentPlayerTime,
        totalWatchTime: an[lessonId].totalWatchTime,
      });
      window.clearInterval(currentInterval.current);
      an[lessonId].currentPlayerTime = 0;
    }
  };

  return (
    <YouTube
      videoId={videoId}
      onReady={ready}
      onStateChange={handlePlayerStateChangeEvent}
      opts={{
        playerVars: {
          start: lessonWatchTime.currentPlayerTime,
          modestbranding: 1, // Oculta a logo do YouTube
          rel: 0, // Oculta vídeos relacionados
          origin: window.location.origin,
        },
      }}
    />
  );
};

export default YouTubePlayerWithAnalyticsReport;
