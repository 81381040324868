import styled from 'styled-components';

export const RedirectToCustomerPortalButton = styled.button`
  font-family: var(--font-sans);
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-white);

  margin-bottom: 8px;
  background-color: unset;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const LogoutButton = styled.button`
  display: flex;
  align-items: center;

  font-family: var(--font-sans);
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-white);

  background-color: unset;
  border: none;

  &:hover {
    cursor: pointer;
  }

  .logout-icon {
    margin-left: 8px;
  }
`;
