import React, { useEffect, useRef, useState } from 'react';
import {
  MdOutlineHome,
  MdOutlineGames,
  MdSlowMotionVideo,
  MdOutlinePersonOutline,
  MdLogout,
  MdPhoneIphone,
} from 'react-icons/md';
import useAppTheme from '../../hooks/useAppTheme';
import useAuth from '../../hooks/useAuth';
import api from '../../services/api';

const linksData = [
  {
    name: 'Home',
    path: '/home',
  },
  {
    name: 'Ranking',
    path: '/ranking',
  },
  // {
  //   name: 'Streamers',
  //   path: '/',
  // },
  // {
  //   name: 'TikTokers',
  //   path: '/',
  // },
];

const items = [
  {
    title: 'Minha Conta',
    icon: (props: any) => <MdOutlinePersonOutline {...props} />,
    path: '/dashboard',
  },
  {
    title: 'Sair',
    icon: (props: any) => <MdLogout {...props} />,
    path: '/',
  },
];

const responsiveItems = [
  {
    title: 'Home',
    icon: (props: any) => <MdOutlineHome {...props} />,
    path: '/home',
  },
  {
    title: 'Ranking',
    icon: (props: any) => <MdOutlineGames {...props} />,
    path: '/',
  },
  // {
  //   title: 'Streamers',
  //   icon: (props: any) => <MdSlowMotionVideo {...props} />,
  //   path: '/',
  // },
  // {
  //   title: 'TikTokers',
  //   icon: (props: any) => <MdPhoneIphone {...props} />,
  //   path: '/',
  // },
  {
    title: 'Minha Conta',
    icon: (props: any) => <MdOutlinePersonOutline {...props} />,
    path: '/dashboard',
  },
  {
    title: 'Sair',
    icon: (props: any) => <MdLogout {...props} />,
    path: '/',
  },
];

type Score = {
  balance: number;
  experiencePoints: number;
};

export const useHeader = () => {
  const { user } = useAuth();
  const [score, setScore] = useState<Score>({} as Score);
  const { isDarkMode, setIsDarkMode } = useAppTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const getStudentScore = async () => {
    const { data } = await api.get<Score>(`/students/${user.id}/score`);
    setScore(data);
  };

  useEffect(() => {
    getStudentScore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const toogleDropdown = () => setIsDropdownOpen(prev => !prev);

  const toogleTheme = () => setIsDarkMode(prev => !prev);
  const getDropdownItems = () =>
    windowSize[0] > 920 ? items : responsiveItems;

  const useOutsideAlerter = (refElement: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (isDropdownOpen) {
          toogleDropdown();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refElement, isDropdownOpen]);
  };

  return {
    score,
    linksData,
    isDarkMode,
    toogleTheme,
    toogleDropdown,
    isDropdownOpen,
    getDropdownItems,
    useOutsideAlerter,
  };
};
