/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MdPlayArrow, MdOutlineInfo } from 'react-icons/md';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Button from '../../components/Button';
import MoreInformationModal from './MoreInformationModal';
import useAppTheme from '../../hooks/useAppTheme';

interface Props {
  isDebut?: boolean;
  titleDark?: string;
  titleLight?: string;
  titlePage?: string;
  title?: string;
  image?: string;
  flag?: string;
  description?: string;
}

const HeroImage = ({
  isDebut = false,
  titleDark = '/assets/roblox.svg',
  titleLight = '/assets/roblox-light.svg',
  title = titleDark,
  titlePage = 'Roblox',
  image = '/assets/hero-image.png',
  flag = '#1 na lista dos Games hoje',
  description = 'Nesse vídeo, você vai aprender com o pessoal da Planeta Arcade como você pode instalar e jogar o roblox no seu computador. Clique e assista já!',
}: Props) => {
  const { isDarkMode } = useAppTheme();
  const { push } = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [infos, setInfos] = useState<Props>({
    isDebut,
    title: isDarkMode ? titleDark : titleLight,
    image,
    flag,
    description,
  });

  const toogleModal = () => {
    setIsOpen(!isOpen);
  };

  const handlePlay = () => {
    push({
      pathname: '/video',
      state: {
        courseName: '',
        video: {
          id: '',
          title: titlePage,
          description,
          videoUri: 'https://www.youtube.com/watch?v=_dci_XIulrk',
        },
      },
    });
  };

  useEffect(() => {
    setInfos({
      isDebut,
      title: isDarkMode ? titleDark : titleLight,
      image,
      flag,
      description,
    });
  }, [isDarkMode]);

  return (
    <Container image={image}>
      {isDebut && (
        <Debut>
          <img src="/assets/logo-grey.svg" alt="Ícone de estreia" />
          <p>Estreia</p>
        </Debut>
      )}
      <Title width={300} src={title} alt="Título do vídeo em destaque" />
      <Flag>
        <img src="/assets/top.svg" alt="Ícone de mais assistidos" />
        <p>{flag}</p>
      </Flag>
      <Description>{description}</Description>
      <Wrapper>
        <ButtonWrapper width="7.5rem">
          <Button onClick={handlePlay} value="Assistir" icon={MdPlayArrow} />
        </ButtonWrapper>
        <ButtonWrapper width="12rem">
          <Button
            backgroundColor="moreInformationButton"
            value="Mais informações"
            icon={MdOutlineInfo}
            onClick={toogleModal}
          />
        </ButtonWrapper>
      </Wrapper>
      <MoreInformationModal
        isOpen={isOpen}
        infos={infos}
        toogleModal={toogleModal}
        onClickPlay={handlePlay}
      />
    </Container>
  );
};

const Container = styled.div<{ image: string }>`
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url(${({ image }) => image});
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 1rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const Debut = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.cantora};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.secondary};

  display: flex;
  align-items: center;
  img {
    margin-right: 0.5rem;
  }
`;

const Title = styled.img`
  margin-top: 1rem;
`;

const Flag = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  p {
    font-family: ${({ theme }) => theme.fonts.cantora};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.title};
    margin-left: 0.5rem;
  }
`;

const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.subTitle};
  margin-top: 1.5rem;
  width: 50%;
  max-width: 40rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const ButtonWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  &:first-child {
    margin-right: 1.5rem;
  }
`;

export default HeroImage;
