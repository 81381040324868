import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import QuizIcon from '@mui/icons-material/Quiz';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import useVideoView from './hooks';
import useSkeleton from '../Skeleton/hooks';
import CourseVideo from '../../models/CourseVideo';
import { VideoViewSkeleton } from '../Skeleton';
import YouTubePlayerWithAnalyticsReport from '../YouTubePlayerWithAnalyticsReport';
import { Quiz } from '../../models/Quiz';
import QuizComponent from '../Quiz/QuizComponent';

interface VideoViewProps {
  video: CourseVideo;
  courseName: string;
  courseId?: string;
  quiz: Quiz;
  onChangeLesson: (lessonId: string) => Promise<void>;
  onChangeQuiz: (quizId: string) => Promise<void>;
}

const VideoView = ({
  video,
  courseName,
  quiz,
  courseId,
  onChangeQuiz,
  onChangeLesson,
}: VideoViewProps) => {
  const {
    lessons,
    quizzes,
    lessonWatchTime,
    reportWatchTime,
    handleClickVideo,
    handleClickQuiz,
    reportVideoDuration,
  } = useVideoView(video, courseName, courseId);

  const { isLoading, setSkeletonLoading } = useSkeleton();
  const [enableQuiz, setEnableQuiz] = useState<boolean>(false);
  const [isVideoContent, setIsVideoContent] = useState<boolean>(true);

  const handleClickVideoLesson = async (lesson: CourseVideo) => {
    setEnableQuiz(false);
    handleClickVideo(lesson);
    await onChangeLesson(lesson.id);
  };

  const handleClickQuizLesson = async (currentQuiz: Quiz) => {
    setEnableQuiz(true);
    handleClickQuiz(currentQuiz);
    await onChangeQuiz(currentQuiz.id);
  };

  const finishSkeletonLoading = () => {
    setSkeletonLoading(false);
  };

  return (
    <VideoViewContainer>
      <Container isLoading={isLoading}>
        {!enableQuiz ? (
          <YouTubePlayerWithAnalyticsReport
            lessonId={video.id}
            videoUrl={video.videoUri}
            reportWatchTime={reportWatchTime}
            lessonWatchTime={lessonWatchTime}
            reportVideoDuration={reportVideoDuration}
            handlePlayerReady={finishSkeletonLoading}
          />
        ) : (
          <QuizComponent quiz={quiz} />
        )}

        <VideoItemContainer>
          <Toolbar>
            <ToolBarItem
              enabled={isVideoContent}
              onClick={() => setIsVideoContent(true)}
              title="Conteúdo"
            >
              <PlayCircleOutlinedIcon fontSize="medium" />
            </ToolBarItem>
            <ToolBarItem
              enabled={!isVideoContent}
              onClick={() => setIsVideoContent(false)}
              title="Questionários"
            >
              <QuizIcon fontSize="medium" />
            </ToolBarItem>
          </Toolbar>
          <ListOfVideos>
            {isVideoContent
              ? lessons.map((lesson, index) => (
                  <Lesson
                    key={lesson.id}
                    onClick={() => handleClickVideoLesson(lesson)}
                  >
                    <Icon isCurrentVideo={lesson.id === video.id}>
                      {String(index + 1)}
                    </Icon>
                    <p title={lesson.title}>
                      {lesson.title.length > 30
                        ? `${lesson.title.substring(0, 30)}...`
                        : lesson.title}
                    </p>
                  </Lesson>
                ))
              : quizzes.map((test, index) => {
                  return (
                    <Lesson
                      key={test.id}
                      onClick={() => handleClickQuizLesson(test)}
                    >
                      <Icon isCurrentVideo={quiz && quiz.id === test.id}>
                        {test.isCompleted || test.attempts === 2 ? (
                          <TaskAltIcon fontSize="small" />
                        ) : (
                          String(index + 1)
                        )}
                      </Icon>
                      <p>{test.title}</p>
                    </Lesson>
                  );
                })}
          </ListOfVideos>
        </VideoItemContainer>
      </Container>
      <VideoViewSkeleton isLoading={isLoading} />
    </VideoViewContainer>
  );
};

const VideoViewContainer = styled.div`
  width: 100%;
`;
const Container = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};

  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
  line-height: 0;

  & iframe {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    @media screen and (max-width: 890px) {
      width: 480px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 1rem;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Toolbar = styled.div`
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  flex-direction: row;
`;
const ToolBarItem = styled.button<{ enabled: boolean }>`
  border: none;
  width: 50%;
  color: ${({ enabled, theme }) =>
    enabled ? theme.colors.primary : theme.colors.themedTitle};
  background-color: transparent;
  height: 100%;
  border-bottom: ${({ enabled, theme }) =>
    enabled ? `2px solid ${theme.colors.primaryLight} ` : 'none'};
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }

  transition: color 0.3s ease-in-out;
`;
const ListOfVideos = styled.ul`
  margin-top: 0.35rem;
  width: 100%;
  height: 290px;
  line-height: 1.5rem;

  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;

  list-style: none;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.primaryOpacityLight};
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    transition: all 0.5s ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;

export const VideoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Lesson = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
  }
  & p {
    width: calc(100% - 48px - 0.5rem);
    font-family: ${({ theme }) => theme.fonts.inter};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.themedTitle};
    margin-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Icon = styled.div<{ isCurrentVideo: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 24px;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.title};
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ isCurrentVideo, theme }) =>
    isCurrentVideo &&
    css`
      width: 40px;
      height: 40px;
      border-radius: 25.5px;
      border: 3px solid ${theme.colors.primaryLight};
    `}
`;

export default VideoView;
