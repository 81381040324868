import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import api from '../../services/api';
import useToast from '../useToast';
import { LocationState } from '../../pages/VideoPage/hooks';
import { Quiz } from '../../models/Quiz';
import useAuth from '../useAuth';

export default function useQuiz() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const { classId, courseId } = useLocation<LocationState>().state;

  const requestQuizzes = useCallback(async () => {
    if (classId && courseId) {
      const { data } = await api.get<Quiz[]>(
        `/quiz/by-class-course/${classId}/${courseId}/${user.id}`,
      );
      setQuizzes(data);
    }
  }, [classId, courseId, user.id]);

  const checkQuestionsAnswers = useCallback(
    async (answers: { [questionId: string]: string }) => {
      try {
        const { data } = await api.post('question/check-answers', {
          answers,
        });

        return data;
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao verificar respostas',
          description:
            'Não foi possível verificar as respostas, tente novamente',
        });
        return {};
      }
    },
    [addToast],
  );

  const completeQuiz = useCallback(
    async (quizId: string, answers: { [questionId: string]: string }) => {
      try {
        const { data } = await api.post('quiz/complete', {
          quizId,
          answers,
          studentId: user.id,
        });

        const note = data.note / 10;
        if (data.isCompleted) {
          addToast({
            type: 'success',
            title: 'Parabéns, você passou no teste!',
            description: `Sua nota foi ${note}!`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (err: any) {
        if (err.response && err.response.data) {
          const { code } = err.response.data;
          switch (code) {
            case 'ALREADY_COMPLETED_QUIZ':
              addToast({
                type: 'error',
                title: 'Quiz já concluído',
                description: 'Você não pode completar o Quiz mais de 2 vezes!',
              });
              break;
            case 'FAILED_QUIZ_FIRST':
              addToast({
                type: 'error',
                title: 'Você não passou no Quiz',
                description: 'Tente completar o Quiz novamente!',
              });
              break;
            case 'FAILED_QUIZ_SECOND':
              addToast({
                type: 'error',
                title: 'Você não passou no Quiz novamente',
                description: 'Continue estudando para os próximos testes!',
              });
              break;
            default:
              addToast({
                type: 'error',
                title: 'Erro ao completar Quiz',
                description:
                  'Não foi possível completar o Quiz, tente novamente',
              });
              break;
          }
        }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    classId && requestQuizzes();
  }, [classId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    quizzes,
    quizClassId: classId,
    checkQuestionsAnswers,
    completeQuiz,
  };
}
