import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* -- CSS Reset Begin -- */

  /* Use a more intuitive box-sizing model */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove default margin and padding from all elements */
  * {
    margin: 0;
    padding: 0;
  }

  /* Allow percentage-based heights in the application */
  html, body {
    height: 100%;
  }

  /* Add accessible line-height and improve text rendering */
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  /* Improve media defaults */
  img, picture, video, canvas, svg {
    display: block;
  }

  /* Remove built-in form typography styles */
  input, button, textarea, select {
    font: inherit;
  }

  /* Avoid text overflows */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  /* Create a root stacking context */
  #root, #__next {
    isolation: isolate;
  }

  /* -- CSS Reset End -- */

  /* -------------------------------------------------- */

  /* Declare global variables here */
  * {
    /* == Color Palette == */
    /* =================== */
    --color-white: #ffffff;
    --color-dark-green: #269912;
    --color-dark-green-hover: #1e7a0e;
    --color-light-green: #3db723;
    --color-dark-blue: #07224b;
    --color-red: #b32111;
    --color-black: #020a17;
    --color-light-grey: #848d9a;
    --color-grey: #3e434b;
  
    /* === Typography === */
    /* ================== */
    --font-sans: 'Roboto', sans-serif;
    --font-display: 'Comfortaa', sans-serif;
  }

  html::-webkit-scrollbar {
    width: 0.5rem;
  }
  html::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.primaryOpacityLight};
  }
  html::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    transition: all 0.50s ease-in-out;
  }
  html::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }
`;
