import React, { useState, useCallback, createContext, useMemo } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import Student from '../models/Student';
import api from '../services/api';
import useErrorHandler from '../hooks/useErrorHandler';

interface AuthData {
  token: string;
  user: Student;
}

interface SignInCredentials {
  email: string;
  password: string;
}

export interface AuthContextState {
  user: Student;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { handleApiError } = useErrorHandler();

  const [authData, setAuthData] = useState<AuthData>(() => {
    const token = localStorage.getItem('@itus:student-token');
    const student = localStorage.getItem('@itus:student-user');

    if (token && student) {
      const { exp } = jwtDecode<JwtPayload>(token);

      if (exp && Date.now() >= exp * 1000) {
        localStorage.removeItem('@itus:student-token');
        localStorage.removeItem('@itus:student-user');

        return {} as AuthData;
      }

      // eslint-disable-next-line dot-notation
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(student) };
    }

    return {} as AuthData;
  });

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const response = await api.post('/students/authenticate', {
        email,
        password,
      });

      if (response.status === 200 && response.data) {
        const { token, user } = response.data;

        localStorage.setItem('@itus:student-token', token);
        localStorage.setItem('@itus:student-user', JSON.stringify(user));

        // eslint-disable-next-line dot-notation
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        setAuthData({ token, user });
      }
    },
    [],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@itus:student-token');
    localStorage.removeItem('@itus:student-user');

    setAuthData({} as AuthData);
  }, []);

  const providerValue = useMemo(
    () => ({ user: authData.user, signIn, signOut }),
    [authData.user, signIn, signOut],
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};
