import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import CourseVideo from '../../models/CourseVideo';
import api from '../../services/api';

interface Props {
  courseId: string;
}

const useVideosRow = ({ courseId }: Props) => {
  const { colors } = useTheme();
  const [margin, setMargin] = useState(0);
  const [videos, setVideos] = useState<CourseVideo[]>([]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const cardSize = 320 + 16;
  const cardsPerRow = {
    current: windowSize[0] / cardSize,
  };
  const totalWidth = videos.length * cardSize;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    cardsPerRow.current = window.innerWidth / cardSize;
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const requestVideos = useCallback(async () => {
    const { data } = await api.get<CourseVideo[]>(
      `/courses/${courseId}/lessons`,
    );
    setVideos(data);
  }, [courseId]);

  const handleNavigationLeft = () => {
    let x = margin + Math.round(windowSize[0] / 1.25);
    if (x > 0) x = 0;
    setMargin(x);
  };

  const handleNavigationRight = () => {
    const maxMargin = totalWidth - (cardSize * cardsPerRow.current - 48);
    const jump = -margin + Math.round(windowSize[0] / 1.25);

    if (jump < maxMargin) setMargin(-jump);
    else if (totalWidth < windowSize[0]) setMargin(margin);
    else setMargin(-maxMargin);
  };

  useEffect(() => {
    requestVideos();
  }, [requestVideos]);

  return {
    colors,
    videos,
    margin,
    handleNavigationLeft,
    handleNavigationRight,
  };
};

export default useVideosRow;
