import { useCallback } from 'react';
import { LessonEvents } from '../../models/LessonAnalyticsData';
import api from '../../services/api';
import useAuth from '../useAuth';

export type VideoWatchTime = {
  totalWatchTime: number;
  currentPlayerTime: number;
  duration?: number;
  lessonId?: string;
};

export default function useLessonAnalytics(lessonId: string) {
  const { user } = useAuth();

  const addEvent = useCallback(
    (eventType: LessonEvents) => {
      api.post(`/lesson-analytics/${lessonId}/events`, {
        studentId: user.id,
        eventType,
      });
    },
    [lessonId, user.id],
  );

  const updateVideoWatchTime = useCallback(
    ({ totalWatchTime, currentPlayerTime }: VideoWatchTime) => {
      api.post(`/lesson-analytics/${lessonId}/watch-time`, {
        studentId: user.id,
        totalWatchTime,
        currentPlayerTime,
      });
    },
    [lessonId, user.id],
  );

  return {
    addEvent,
    updateVideoWatchTime,
  };
}
