import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface ItemProps {
  title: string;
  icon: (props: any) => React.ReactNode;
  path: string;
}

interface Props {
  isOpen: boolean;
  items: ItemProps[];
  toogleDropdown: () => void;
}

const Dropdown = ({ isOpen, toogleDropdown, items }: Props) => {
  const { signOut } = useAuth();
  const { colors } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const useOutsideAlerter = (refElement: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        const isClickOutside =
          isOpen &&
          refElement?.current &&
          !refElement?.current.contains(event?.target) &&
          !String(event?.target?.className)?.includes('user-info');
        if (isClickOutside) {
          toogleDropdown();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refElement, isOpen]);
  };

  const handleClickItem = useCallback(
    (e: any) => e.currentTarget.textContent === 'Sair' && signOut(),
    [signOut],
  );

  const memoList = useMemo(
    () =>
      items.map(item => {
        return (
          <Item key={item.title} onClick={handleClickItem}>
            <Link to={item.path}>
              {item.icon({ size: 24, color: colors.title })}
              <span>{item.title}</span>
            </Link>
          </Item>
        );
      }),
    [colors.title, items, handleClickItem],
  );

  useOutsideAlerter(ref);

  return (
    <Container ref={ref} isOpen={isOpen}>
      <Icon size={40} color={colors.header} />
      <Items>{memoList}</Items>
    </Container>
  );
};

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.title};
`;
const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 11vh;
  right: 0;
  width: 250%;
  background-color: ${({ theme }) => theme.colors.header};
  border-radius: 0.5rem;
  display: block;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  z-index: 99;
`;

const Icon = styled(MdOutlineArrowDropDown)`
  position: absolute;
  top: -30px;
  right: 20px;
`;

const Items = styled.ul`
  list-style: none;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.title};
  border-bottom: 1px solid ${({ theme }) => theme.colors.opacityButton};
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityIcon};
  }

  &:last-child {
    border-bottom: none;
  }

  & span {
    margin-left: 0.5rem;
  }
  & a {
    padding: 1rem 2rem;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;

    text-decoration: none;
    color: ${({ theme }) => theme.colors.title};
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.title};
`;

export default Dropdown;
