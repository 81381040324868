import React from 'react';

import './styles.scss';

interface SectionHeaderProps {
  children: React.ReactNode;
}

const SectionHeader = ({ children }: SectionHeaderProps) => {
  return (
    <header className="section-header">
      <div className="section-header__content">
        {React.Children.map(children, (child, index) => {
          /*
           * Checks if the component is valid (avoiding typescript problems) and
           * if the component is some variant of a header (h1, h2, h3 etc.). This
           * way anything else (like icons and such) doesn't get targeted by rules
           * intended to be applied on text.
           */
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              // @ts-ignore
              className: `${
                child.type.toString().startsWith('h') &&
                'section-header__content__title'
              } ${index > 0 && 'title--space-left'}`,
            });
          }
          return child;
        })}
      </div>
      <div className="section-header__horizontal-divider" />
    </header>
  );
};

export default SectionHeader;
