import React from 'react';
import styled from 'styled-components';
import Teacher from '../../models/Teacher';

interface Props {
  teachers: Teacher[];
}

const Teachers = ({ teachers }: Props) => {
  return (
    <Container>
      {teachers.map(teacher => (
        <TeacherItem key={teacher.id}>
          <img
            src={`https://api.multiavatar.com/${teacher.profile.name}.svg`}
            alt={`Avatar de ${teacher.profile.name}`}
          />
          <Info>
            <TeacherName>{teacher.profile.name}</TeacherName>
            <TeacherEmail>{teacher.profile.email}</TeacherEmail>
          </Info>
        </TeacherItem>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0 1rem;
  margin-top: 1rem;
`;

const TeacherItem = styled.li`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  &:first-child {
    margin-top: 0;
  }

  & img {
    width: 3.5rem;
    margin-right: 1rem;
  }
`;

const Info = styled.div``;

const TeacherName = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.primary};
`;

const TeacherEmail = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.themedTitle};
`;

export default Teachers;
