import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { GetObjectCommand, ListObjectsV2Command } from '@aws-sdk/client-s3';
import {
  MdAudiotrack,
  MdCode,
  MdDescription,
  MdFolder,
  MdInsertDriveFile,
  MdPhoto,
  MdPlayCircle,
} from 'react-icons/md';
import useVideoPage from '../../pages/VideoPage/hooks';
import useAttachment from './hooks';

interface Props {
  lessonId: string;
}

const LessonAttachment = ({ lessonId }: Props) => {
  const { colors } = useVideoPage();

  const { attachments, downloadAttachment } = useAttachment(lessonId);

  const handleDownload = async (key: string) => {
    if (key.length <= 0) return;
    await downloadAttachment(key);
  };

  return (
    <>
      {attachments.length > 0 && <Title>Anexos</Title>}

      <AttachmentList>
        {attachments.map(attachment => {
          const { title, fileType, fileSize, key } = attachment;
          const size = Math.floor(Number(fileSize) / 100000);
          const [_, type] = fileType.split('/');
          let formatIcon;

          switch (type) {
            case 'pdf':
              formatIcon = <MdInsertDriveFile size={16} color={colors.alert} />;
              break;
            case 'txt':
              formatIcon = <MdDescription size={16} color={colors.primary} />;
              break;
            case 'zip':
              formatIcon = <MdFolder size={16} color="#D2B48C" />;
              break;
            case 'mp4':
              formatIcon = <MdPlayCircle size={16} color={colors.primary} />;
              break;
            case 'mp3':
              formatIcon = <MdAudiotrack size={16} color={colors.primary} />;
              break;
            case 'js':
              formatIcon = <MdCode size={16} color={colors.primary} />;
              break;
            default:
              formatIcon = <MdPhoto size={16} color="#6E8BFA" />;
              break;
          }

          return (
            <li key={attachment.id}>
              <AttachmentButton
                type="button"
                onClick={() => handleDownload(key)}
              >
                {title}
                <div>
                  {formatIcon}
                  <span>{type}</span>
                  <span>{size}MB</span>
                </div>
              </AttachmentButton>
            </li>
          );
        })}
      </AttachmentList>
    </>
  );
};

const AttachmentButton = styled.button`
  background-color: #e5e5e5;
  padding: 1rem 1rem;
  border-radius: 5px;
  min-width: 100px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  text-align: start;
  border: none;
  box-shadow: 0 0 2px 0 rgba(27, 25, 25, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #fbfbfb;
    // Box shadow all the way around and with blur
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  }
  div {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    color: #777171;
    justify-content: space-between;
    font-size: 0.5rem;
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.themedTitle};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
  & p {
    margin: 0 1rem;

    &:nth-child(-n + 5) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    & p {
      margin: 0 0.5rem;
    }
  }
`;
const AttachmentList = styled.ul`
  display: flex;
  margin-top: 1.5rem;
  flex-wrap: wrap;
  list-style: none;
  gap: 1.5rem;
`;
export default LessonAttachment;
