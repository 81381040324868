import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Button from '../Button';
import useFeedback from './hooks';
import Star from './star';
import { Comment } from './Comment';

interface Props {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  lessonId: string;
}

const Feedback = ({ register, setValue, lessonId }: Props) => {
  const {
    user,
    stars,
    colors,
    comments,
    hoveredStar,
    deleteComment,
    handleComments,
    handleClickStar,
    handleMouseEnter,
    loadFeedbackComments,
    selectedStar,
  } = useFeedback(setValue);

  const [commentValue, setCommentValue] = useState<string>('');
  const handlePublishClick = () => {
    handleComments(commentValue);
    setCommentValue('');
  };

  useEffect(() => {
    loadFeedbackComments(lessonId);
  }, [lessonId]); // eslint-disable-line

  const [userComment, setUserComments] = useState<number>(0);
  useEffect(() => {
    const filteredComments = comments.filter(
      comment => comment.student_id === user.id,
    );
    setUserComments(filteredComments.length);
  }, [comments, user.id]);

  return (
    <Container>
      <Header>
        <input {...register('rating')} type="hidden" />
        {stars.map(star => (
          <Star
            key={star}
            star={star}
            onMouseEnter={handleMouseEnter}
            onClick={handleClickStar}
            background={
              star > selectedStar ? 'transparent' : colors.primaryDark
            }
            stroke={star > hoveredStar ? colors.primaryDark : colors.primary}
          />
        ))}
      </Header>
      <Wrapper>
        <label htmlFor="comment">Comentário</label>
        <textarea
          id="comment"
          {...register('comment')}
          placeholder="Conte pra gente o que achou!"
          value={commentValue}
          onChange={e => setCommentValue(e.target.value)}
        />
        <ButtonContainer>
          <Button
            value="Publicar"
            onClick={handlePublishClick}
            disabled={userComment >= 5}
            title={
              userComment >= 5
                ? 'Você já atingiu o limite de comentários'
                : 'Publicar'
            }
          />
        </ButtonContainer>
        <CommentList>
          {comments.map(comment => (
            <Comment
              key={comment.id}
              userId={user.id}
              commentId={comment.id}
              content={comment.comment}
              studentId={comment.student_id}
              avatarUrl={comment.avatar_url}
              onDeleteComment={deleteComment}
              publishedAt={comment.created_at}
              studentName={comment.student_name}
            />
          ))}
        </CommentList>
      </Wrapper>
    </Container>
  );
};

const CommentList = styled.div``;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg {
    width: 50px;
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  padding: 1.5rem 2.5rem;
  border-radius: 1rem;
  margin-top: 2rem;
  width: 100%;

  & label {
    font-family: ${({ theme }) => theme.fonts.inter};
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.themedTitle};
  }
  & textarea {
    width: 100%;
    height: 50%;
    min-height: 80px;
    font-family: ${({ theme }) => theme.fonts.inter};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.themedTitle};
    padding: 1rem 0.5rem;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.background};
    margin-top: 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  width: 120px;
  margin-top: 0.5rem;
`;

export default Feedback;
