import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import UserMenu from './components/UserMenu';

import api from '../../services/api';

import {
  NavBarContainer,
  NavBarContent,
  NavBarBurguerMenu,
  UserInformation,
  Vanishable,
  UserContainer,
  UserMenuContainer,
} from './styles';

interface StudentAvatar {
  avatarUri: string;
}

const NavigationBar = () => {
  const { user } = useAuth();

  const [shouldShowMenu, setShouldShowMenu] = useState(false);

  const [avatar, setAvatar] = useState<StudentAvatar>();

  useEffect(() => {
    async function resetAvatar() {
      const { data } = await api.get<StudentAvatar>(`/students/${user.id}`);
      setAvatar(data);
    }

    resetAvatar();
  }, [user.id]);

  const userAvatarUrl = useMemo(() => {
    const avatarToken = avatar?.avatarUri
      ? avatar.avatarUri
      : user.profile.name;
    return `https://api.multiavatar.com/${avatarToken}.svg`;
  }, [avatar, user.profile.name]);

  const handleToggleMenu = useCallback(() => {
    setShouldShowMenu(prevValue => !prevValue);
  }, []);
  return (
    <NavBarContainer>
      <NavBarContent>
        <Link to="/dashboard">
          <img
            src="/assets/itus-logo.svg"
            alt="Itus Logo"
            className="itus-logo"
          />
        </Link>

        {/* TODO: Search bar */}

        <Vanishable at={768}>
          <UserContainer>
            <UserInformation onClick={handleToggleMenu}>
              <p className="user-name">{user.profile.name}</p>
              <img
                src={userAvatarUrl}
                alt="Avatar do usuário"
                className="user-avatar"
              />
            </UserInformation>
            <UserMenuContainer shouldShow={shouldShowMenu}>
              <UserMenu />
            </UserMenuContainer>
          </UserContainer>
        </Vanishable>

        <NavBarBurguerMenu />
      </NavBarContent>
    </NavBarContainer>
  );
};

export default NavigationBar;
