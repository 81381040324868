/* eslint-disable no-return-assign */
import React, { Fragment, useState, useEffect } from 'react';
import useAuth from '../../../../hooks/useAuth';
import api from '../../../../services/api';

import './styles.scss';
import SectionHeader from '../../../../components/SectionHeader';

import ClassCourseList from './ClassCourseList/index';

interface ApiClass {
  id: string;
  title: string;
  synchronousClassLink: string | null;
}

const StudentClassCoursesList = () => {
  const { user } = useAuth();

  const [classes, setClasses] = useState<ApiClass[]>([]);

  useEffect(() => {
    async function requestClasses() {
      const { data } = await api.get<ApiClass[]>(
        `/students/${user.id}/classes`,
        {},
      );
      setClasses(data);
    }
    requestClasses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section id="my-classes">
      {classes &&
        classes.map(classe => (
          <Fragment key={classe.id}>
            <SectionHeader>
              <h1>{classe.title}</h1>
            </SectionHeader>
            {classe.synchronousClassLink && (
              <div className="my-classes__class-link-container">
                <a
                  className="my-classes__class-link-container__link"
                  href={classe.synchronousClassLink}
                >
                  Link de Aula Ao Vivo
                </a>
              </div>
            )}
            <ClassCourseList classId={classe.id} />
          </Fragment>
        ))}
    </section>
  );
};

export default StudentClassCoursesList;
