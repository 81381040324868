import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';
import useToast from '../../hooks/useToast';
import { resetPasswordFields, resetPasswordSchema } from './scheme';
import api from '../../services/api';
import useQueryParams from '../../hooks/useQueryParams';

export const useResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const query = useQueryParams();
  const { addToast } = useToast();
  const { colors } = useTheme();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const onSubmit = useCallback(
    async (form: any) => {
      setIsLoading(true);
      try {
        const { token } = query;
        await api.post('/users/reset-password', {
          token,
          password: form.password,
          passwordConfirmation: form.passwordConfirmation,
        });
        addToast({
          type: 'success',
          title: 'Senha alterada com sucesso',
          description: 'Você já pode fazer login com sua nova senha',
        });
        push('/login');
      } catch (err: any) {
        resetPasswordFields.forEach(field => {
          setError(field.name, {
            type: 'manual',
            message: err?.response?.data?.error,
          });
        });
        addToast({
          type: 'error',
          title: 'Erro ao redefiner senha',
          description:
            'Não foi redefinir sua senha. Verifique se o token está correto e tente novamente.',
        });
        setIsLoading(false);
      }
    },
    [addToast, push, setError, query],
  );

  const clearAllErrors = useCallback(() => {
    if (errors) clearErrors();
  }, [errors, clearErrors]);

  return {
    clearAllErrors,
    colors,
    errors,
    handleSubmit,
    isLoading,
    isValid,
    onSubmit,
    register,
  };
};
