import React, { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';

interface ApiCourse {
  id: string;
  title: string;
  description: string;
  thumbnailUrl?: string;
}

interface Props {
  classId: string;
}

const useCoursesContainer = ({ classId }: Props) => {
  const [courses, setCourses] = useState<ApiCourse[]>([]);

  const requestCourse = useCallback(async () => {
    const { data } = await api.get<ApiCourse[]>(`/classes/${classId}/courses`);
    setCourses(data);
  }, [classId]);

  useEffect(() => {
    requestCourse();
  }, [requestCourse]);

  return { courses };
};

export default useCoursesContainer;
