import React from 'react';
import * as Yup from 'yup';
import { MdOutlineLock } from 'react-icons/md';

export const resetPasswordSchema = Yup.object({
  password: Yup.string().required('Senha obrigatória'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required(),
}).required();

export const resetPasswordFields = [
  {
    name: 'password',
    label: 'Senha',
    type: 'input',
    placeholder: 'Digite sua nova senha',
    required: true,
    icon: (props: any) => <MdOutlineLock {...props} />,
  },
  {
    name: 'passwordConfirmation',
    label: 'Confirmação de senha',
    type: 'input',
    placeholder: 'Confirme sua nova senha',
    required: true,
    icon: (props: any) => <MdOutlineLock {...props} />,
  },
];
