// @ts-ignore
import React from 'react';
import styled from 'styled-components';
import VideosRow from '../VideosRow';
import useCoursesContainer from './hooks';

interface ApiCourse {
  id: string;
  title: string;
  description: string;
  thumbnailUrl?: string;
}

interface ApiClass {
  id: string;
  title: string;
  synchronousClassLink: string | null;
}

interface Props {
  classCourse: ApiClass;
}

const CoursesContainer = ({ classCourse }: Props) => {
  const { courses } = useCoursesContainer({
    classId: classCourse.id,
  });
  return (
    <Container>
      {courses.map((course: ApiCourse) => (
        <Wrapper key={course.id}>
          <Title>{course.title}</Title>
          <VideosRow
            classId={classCourse.id}
            courseTitle={course.title}
            courseId={course.id}
          />
        </Wrapper>
      ))}
    </Container>
  );
};

const Container = styled.div``;

const Wrapper = styled.div`
  position: relative;
  padding: 0 0 1.5rem 2rem;
  overflow-x: hidden;

  &:hover div.arrow {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    div > svg {
      opacity: 1;
    }
  }
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.cantora};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 0.75rem;
`;

export default CoursesContainer;
