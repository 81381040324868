export interface ErrorHandlerMap {
  [key: string]: (error?: Error) => Promise<void>;
}

export const errorHandlerMap: ErrorHandlerMap = {
  EMAIL_ALREADY_IN_USE: async () => {
    // eslint-disable-next-line no-alert
    window.alert('Este e-Mail já está cadastrado!');
  },
};
