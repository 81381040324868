import { useLocation } from 'react-router';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import CourseVideo from '../../models/CourseVideo';
import { feedbackSchema } from './scheme';
import useToast from '../../hooks/useToast';
import api from '../../services/api';
import useAuth from '../../hooks/useAuth';
import Teacher from '../../models/Teacher';
import { useAvatarUrl } from '../../hooks/useAvatarUrl';
import { Quiz } from '../../models/Quiz';

export interface LocationState {
  courseId: string;
  courseName: string;
  video: CourseVideo;
  classId: string;
  quiz: Quiz;
}

const useVideoPage = () => {
  const { user } = useAuth();
  const { courseId, courseName, video, quiz } =
    useLocation<LocationState>().state;
  const { colors } = useTheme();
  const { addToast } = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(feedbackSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const avatar = useAvatarUrl();

  const onDeleteFeedback = async (
    id: string,
    studentId: string,
  ): Promise<boolean> => {
    try {
      const { status } = await api.delete(
        `/feedbacks/${id}?studentId=${studentId}`,
      );
      if (status === 200) {
        addToast({
          type: 'success',
          title: 'Feedback deletado com sucesso',
          description: 'Obrigado por nos ajudar a melhorar',
        });
        return true;
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops... algo deu errado',
        description: 'Erro ao deletar feedback',
      });
    }
    return false;
  };

  const onSubmit = async (form: any) => {
    try {
      const rating = !form.rating ? 3 : form.rating;
      await api.post('/feedbacks/register', {
        studentId: user.id,
        lessonId: video.id,
        rating,
        comment: form.comment,
        avatar_url: avatar.userAvatarUrl,
      });
      addToast({
        type: 'success',
        title: 'Feedback enviado com sucesso',
        description: 'Obrigado por nos ajudar a melhorar',
      });
      return true;
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops... algo deu errado',
        description: 'Erro ao enviar feedback',
      });
      return false;
    }
  };

  useEffect(() => {
    if (errors.rating) {
      addToast({
        type: 'error',
        title: 'Ops... algo deu errado',
        description: 'Você precisa avaliar o vídeo',
      });
    }
  }, [errors, addToast]);

  const getTeachersByCourseId = useCallback(async () => {
    // const { data } = await api.get(`/teachers/from-course/${courseId}`);
    // setTeachers(data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      // TODO: Adicionar permissão de aluno no endpoint
      //  getTeachersByCourseId();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops... algo deu errado',
        description: 'Erro ao buscar professores',
      });
    }
  }, [addToast, getTeachersByCourseId]);

  return {
    quiz,
    user,
    video,
    colors,
    onSubmit,
    setValue,
    courseId,
    register,
    teachers,
    courseName,
    handleSubmit,
    onDeleteFeedback,
  };
};

export default useVideoPage;
