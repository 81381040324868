import React, { CSSProperties } from 'react';

interface DoubleArrowIconProps {
  color: string;
  size: number;
  style?: CSSProperties;
}

const DoubleArrowIcon = ({ color, size, style = {} }: DoubleArrowIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      style={style}
    >
      <path
        d="M7.96 21.9199C7.19 21.9199 6.48002 21.5499 6.17002 20.9999C5.86002 20.4399 6.04 19.8199 6.65 19.3599C8.64 17.8399 10.64 16.3199 12.63 14.8099C14.06 13.7199 15.49 12.6299 16.92 11.5499C17.06 11.4399 17.06 11.3899 16.92 11.2899C13.5 8.69991 10.09 6.09987 6.68 3.49987C5.98 2.95987 5.83 2.30991 6.28 1.65991C6.81 0.899907 8.22999 0.669843 9.10999 1.21984C9.69999 1.58984 10.21 2.01993 10.74 2.42993C14.2 5.05993 17.65 7.68982 21.1 10.3198C21.8 10.8498 21.94 11.5099 21.48 12.1599C21.38 12.2999 21.24 12.4099 21.1 12.5199C17.2 15.4899 13.3 18.4599 9.39002 21.4299C9.00002 21.7299 8.53 21.9099 7.96 21.9199Z"
        fill={color}
      />
      <path
        d="M2.23001 18.1297C1.74001 18.1297 1.27999 17.8997 1.08999 17.5398C0.889995 17.1798 1.00999 16.7897 1.39999 16.4897C2.66999 15.5197 3.95001 14.5497 5.23001 13.5797C6.14001 12.8797 7.06 12.1897 7.97 11.4897C8.06 11.4197 8.06 11.3897 7.97 11.3197C5.78 9.65966 3.60001 7.99968 1.42001 6.33968C0.970012 5.99968 0.880002 5.57975 1.16 5.16975C1.5 4.67975 2.41 4.53973 2.97 4.88973C3.35 5.12973 3.66999 5.39975 4.01999 5.66975C6.22999 7.34975 8.43999 9.02968 10.65 10.7197C11.1 11.0597 11.18 11.4797 10.89 11.8997C10.83 11.9897 10.74 12.0597 10.65 12.1297C8.14999 14.0297 5.66 15.9297 3.16 17.8297C2.9 18.0097 2.60001 18.1297 2.23001 18.1297Z"
        fill={color}
      />
    </svg>
  );
};

export default DoubleArrowIcon;
