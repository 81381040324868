import React from 'react';
import styled from 'styled-components';

interface Props {
  background: string;
  stroke: string;
  onMouseEnter: (index: number) => void;
  onClick: (index: number) => void;
  star: number;
}

const Star = ({ background, stroke, onMouseEnter, onClick, star }: Props) => (
  <Contariner
    width="78"
    height="75"
    viewBox="0 0 78 75"
    fill={background}
    xmlns="http://www.w3.org/2000/svg"
    onMouseEnter={() => onMouseEnter(star)}
    onMouseLeave={() => onMouseEnter(0)}
    onClick={() => onClick(star)}
  >
    <path
      d="M40.5814 61.5328L58.6939 73.0328C61.0298 74.5062 63.9048 72.314 63.222 69.6187L57.9751 48.9906C57.8332 48.4188 57.8558 47.8186 58.0402 47.2591C58.2246 46.6995 58.5633 46.2035 59.0173 45.8281L75.261 32.2797C77.3814 30.5187 76.3032 26.9609 73.536 26.7812L52.3329 25.4156C51.7544 25.3819 51.1982 25.1805 50.7323 24.8358C50.2664 24.4912 49.911 24.0183 49.7095 23.475L41.8032 3.56559C41.5939 2.99024 41.2127 2.49323 40.7112 2.14202C40.2097 1.79081 39.6123 1.60242 39.0001 1.60242C38.3879 1.60242 37.7905 1.79081 37.289 2.14202C36.7875 2.49323 36.4063 2.99024 36.197 3.56559L28.2907 23.475C28.0892 24.0183 27.7338 24.4912 27.2679 24.8358C26.802 25.1805 26.2458 25.3819 25.6673 25.4156L4.46417 26.7812C1.69698 26.9609 0.618857 30.5187 2.73917 32.2797L18.9829 45.8281C19.4369 46.2035 19.7756 46.6995 19.96 47.2591C20.1444 47.8186 20.167 48.4188 20.0251 48.9906L15.1735 68.1093C14.347 71.3437 17.797 73.9672 20.5642 72.2062L37.4189 61.5328C37.8915 61.2322 38.44 61.0726 39.0001 61.0726C39.5602 61.0726 40.1087 61.2322 40.5814 61.5328V61.5328Z"
      stroke={stroke}
      strokeWidth="2.625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Contariner>
);

const Contariner = styled.svg`
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  & path {
    transition: all 0.2s ease-in-out;
  }
`;

export default Star;
