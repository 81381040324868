import { S3Client } from '@aws-sdk/client-s3';

const config = {
  AMAZON_S3_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID || '',
  AMAZON_S3_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY || '',
  AWS_DEFAULT_REGION: process.env.AWS_DEFAULT_REGION || 'us-east-1',
  AWS_S3_BUCKET: process.env.AWS_S3_BUCKET || 'itus-hub-lessons-attachments',
};

export const useS3Client = () => {
  return {
    aws: new S3Client({
      region: config.AWS_DEFAULT_REGION,
      credentials: {
        accessKeyId: config.AMAZON_S3_ACCESS_KEY_ID,
        secretAccessKey: config.AMAZON_S3_SECRET_ACCESS_KEY,
      },
    }),
    config,
  };
};
