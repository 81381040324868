import React from 'react';
import { MdPlayArrow } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router';
import styled, { useTheme } from 'styled-components';
import CourseVideo from '../../models/CourseVideo';

interface Props {
  video: CourseVideo;
  courseId: string;
  courseTitle: string;
  classId: string;
  isLast: boolean;
}

const CardVideo = ({
  video,
  courseId,
  courseTitle,
  isLast,
  classId,
}: Props) => {
  const { push } = useHistory();
  const { colors } = useTheme();

  const handlePressCard = () => {
    push({
      pathname: '/video',
      state: {
        video,
        classId,
        courseId,
        courseName: courseTitle,
      },
    });
  };

  return (
    <Container isLast={isLast} onClick={handlePressCard}>
      <Thumb
        src={`https://i.ytimg.com/vi/${video.videoUri.slice(
          video.videoUri.indexOf('embed/') + 6,
        )}/hqdefault.jpg`}
      >
        <HoverEffect>
          <MdPlayArrow size={56} color={colors.title} />
        </HoverEffect>
      </Thumb>
      <Divider />
      <Infos>
        <p>{video.title}</p>
      </Infos>
    </Container>
  );
};

const Container = styled.div<{ isLast: boolean }>`
  width: 320px;
  z-index: 1;

  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background};

  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  transition: all 0.3s ease-in-out;

  &:hover div {
    cursor: pointer;
    opacity: 1;
  }
`;

const Thumb = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 320px;
  height: 11rem;
  background-size: cover;
  background-position: center;
`;

const HoverEffect = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primaryOpacity};
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    border-radius: 8px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: ${({ theme }) => theme.colors.opacityButton};
    }
  }
`;

const Divider = styled.div`
  height: 0.35rem;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Infos = styled.div`
  padding: 0.6rem 0.5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  & p {
    font-family: ${({ theme }) => theme.fonts.inter};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.themedTitle};

    white-space: nowrap;
    overflow: hidden; /* "overflow" value must be different from "visible" */
    text-overflow: ellipsis;
  }
`;

export default CardVideo;
