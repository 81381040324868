import styled, { css } from 'styled-components';

export const EmptySectionText = styled.div`
  text-align: center;

  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #919191;
  text-transform: uppercase;
`;
