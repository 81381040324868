import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useTheme } from 'styled-components';
import api from '../../services/api';
import LessonFeedback from '../../models/LessonFeedback';
import useVideoPage from '../../pages/VideoPage/hooks';
import { useAvatarUrl } from '../../hooks/useAvatarUrl';

const useFeedback = (setValue: UseFormSetValue<any>) => {
  const { colors } = useTheme();
  const [stars] = useState([1, 2, 3, 4, 5]);
  const [hoveredStar, setHoveredStar] = useState(0);
  const [selectedStar, setSelectedStar] = useState(0);
  const { video, user, onDeleteFeedback } = useVideoPage();
  const [comments, setComments] = useState<LessonFeedback[]>([]);
  const avatar = useAvatarUrl();

  const handleComments = (lessonFeedbackComment: string) => {
    setComments([
      ...comments,
      {
        id: Math.random().toString(),
        rating: 3,
        comment: lessonFeedbackComment,
        lesson_id: Math.random().toString(),
        student_id: user.id,
        avatar_url: avatar.userAvatarUrl,
        student_name: user.profile.name || user.profile.email.split('@')[0],
        created_at: new Date(),
      },
    ]);
  };
  async function deleteComment(id: string, studentId: string) {
    const deletedWithSucess = await onDeleteFeedback(id, studentId);
    if (!deletedWithSucess) return;
    setComments(comments.filter(comment => comment.id !== id));
  }

  const handleMouseEnter = (index: number) => {
    setHoveredStar(index);
  };

  const handleClickStar = (index: number) => {
    setSelectedStar(index);
  };

  const loadFeedbackComments = async (lessonId: string) => {
    try {
      if (!lessonId) return;
      const { data } = await api.get<LessonFeedback[]>(
        `/feedbacks/get-ratings-by-lesson/${lessonId}`,
      );
      const commentsOrderedByCreatedAt = data.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
      setComments(commentsOrderedByCreatedAt);
    } catch (error) {
      // intentionally do nothing
    }
  };

  useEffect(() => {
    loadFeedbackComments(video.id);
  },[]); // eslint-disable-line

  useEffect(() => {
    setValue('rating', selectedStar);
  }, [selectedStar]); // eslint-disable-line

  return {
    colors,
    stars,
    comments,
    user,
    handleComments,
    deleteComment,
    loadFeedbackComments,
    selectedStar,
    hoveredStar,
    handleMouseEnter,
    handleClickStar,
  };
};

export default useFeedback;
