import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { MdAvTimer, MdOndemandVideo, MdModeEdit } from 'react-icons/md';
import api from '../../../../services/api';

import useAuth from '../../../../hooks/useAuth';
import './style.scss';

import useErrorHandler from '../../../../hooks/useErrorHandler';

import SectionHeader from '../../../../components/SectionHeader';

interface ApiCourse {
  id: string;
  title: string;
  description: string;
}

interface ApiCoursePagination {
  courses: ApiCourse[];
  total: number;
}

interface StudentAvatar {
  avatarUri: string;
}

const StudentInfo = () => {
  const { user } = useAuth();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [courses, setCourses] = useState<ApiCourse[]>([]);
  useEffect(() => {
    async function requestCourses() {
      const { data } = await api.get<ApiCoursePagination>(
        `/courses/from-student/${user.id}`,
        {
          params: {
            page,
            take: 4,
          },
        },
      );

      setCourses(data.courses);
      setMaxPage(Math.ceil(data.total / 4));
    }
    requestCourses();
  }, [user.id, page]);

  const handlePageSelection = useCallback(
    (selectedPage: number) => {
      if (selectedPage > 0 && selectedPage <= maxPage) {
        setPage(selectedPage);
      }
    },
    [maxPage],
  );

  const [avatar, setAvatar] = useState<StudentAvatar>();

  useEffect(() => {
    async function resetAvatar() {
      const { data } = await api.get<StudentAvatar>(`/students/${user.id}`);
      setAvatar(data);
    }
    resetAvatar();
  }, [user.id]);

  const userAvatarUrl = useMemo(() => {
    const avatarToken = avatar?.avatarUri
      ? avatar.avatarUri
      : user.profile.name;
    return `https://api.multiavatar.com/${avatarToken}.svg`;
  }, [avatar, user.profile.name]);

  const changeAvatar = useCallback(() => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    async function setNewAvatar() {
      const { data } = await api.put(`/students/update`, {
        id: user.id,
        avatarUri: result,
      });
    }
    setNewAvatar();
    async function resetAvatar() {
      const { data } = await api.get<StudentAvatar>(`/students/${user.id}`);
      setAvatar(data);
    }
    resetAvatar();
  }, [user.id]);

  return (
    <div id="student-info">
      <SectionHeader>
        <h1>{user.profile.name}</h1>
      </SectionHeader>

      <section id="student-info-container">
        <div className="flex-row">
          <img src={userAvatarUrl} alt="Avatar" id="student-avatar" />
          <MdModeEdit
            size={20}
            id="change-avatar"
            color="#07224B"
            title="Gerar novo avatar aleatório"
            onClick={changeAvatar}
          />
        </div>
        <div id="student-main-info">
          <h2 className="typography-sans font-blue">Sobre</h2>
          <p className="typography-sans font-blue">
            Cursos:
            {courses && courses.map(course => course.title.concat(' '))}
          </p>
          <p className="typography-sans font-blue">Turma:</p>
          <p className="typography-sans font-blue">{`Aluno: ${user.profile.name}`}</p>
          <p className="typography-sans font-blue fonts">
            Interesses: Sem interesses associados
          </p>
        </div>

        <div id="cards-container">
          <div className="card-box">
            <MdAvTimer size={60} color="#269912" />
            <p className="typography-sans font-white"> Tempo na plataforma:</p>
            <h3 className="typography-sans font-white">00</h3>
          </div>
          <div className="card-box">
            <MdOndemandVideo size={60} color="#269912" />
            <p className="typography-sans font-white"> Aulas assistidas:</p>
            <h3 className="typography-sans font-white">00</h3>
          </div>
          <div className="card-box">
            <img src="/assets/coin.svg" alt="Itus" id="it-coin" />
            <p className="typography-sans font-white"> ITcoins:</p>
            <h3 className="typography-sans font-white">00</h3>
          </div>
        </div>
        {/* <div id="student-photo-container">
          <img src={userAvatarUrl} alt="Avatar" id="student-photo" />
        </div>
        <div id="main-info-container">
          <h2 id="main-info-header" className="typography-sans font-blue">
            Sobre
          </h2>
          <div id="main-info-body">
            <p className="typography-sans font-blue">
              Cursos:
              {courses.map(course => course.title.concat(', '))}
            </p>
            <p className="typography-sans font-blue">Turma: </p>
            <p className="typography-sans font-blue">{`Aluno: ${user.profile.name}`}</p>
            <div id="interests" className="flex-row justify-space">
              <p className="typography-sans font-blue fonts">Interesses:</p>
              {/* TO DO: <MdAdd size={20} color="#269912" id="add-interest" /> }
            </div>
          </div>
        </div>
        <div id="extra-info-container">
          <div className="extra-info-box">
            <MdAvTimer size={60} color="#269912" />
            <h3 className="typography-sans font-white">00</h3>
            <p className="typography-sans font-white"> Tempo na plataforma</p>
          </div>
          <div className="extra-info-box">
            <MdOndemandVideo size={60} color="#269912" />
            <h3 className="typography-sans font-white">00</h3>
            <p className="typography-sans font-white"> Aulas assistidas</p>
          </div>
          <div className="extra-info-box">
            <img src="/assets/coin.svg" alt="Itus" />
            <h3 className="typography-sans font-white">00</h3>
            <p className="typography-sans font-white"> ITcoins</p>
          </div>
        </div> */}
      </section>
      {/* <div id="progress" className="flex-column">
           <div id="progress-text" className="flex-row justify-space">
            <p className="typography-sans font-blue">Andamento</p>
            <p id="percent" className="typography-sans font-blue align-left">
              0% Concluído
            </p>
           </div>
          <div id="progress-bar" /> 
         </div> */}
    </div>
  );
};

export default StudentInfo;
