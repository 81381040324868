import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { AuthProvider } from './AuthContext';
import { ErrorsContextProvider } from './ErrorsContext';
import { ToastProvider } from './ToastContext';
import { ThemeProvider } from './ThemeContext';

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ThemeProvider>
      <HelmetProvider>
        <ToastProvider>
          <ErrorsContextProvider>
            <AuthProvider>{children}</AuthProvider>
          </ErrorsContextProvider>
        </ToastProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
