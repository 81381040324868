import styled from 'styled-components';

export const CourseTeachersComponent = styled.section``;

export const TeacherInfo = styled.div`
  width: 100%;
  height: auto;
  border: solid;
  border-width: 1px;
  border-color: #07224b;
  border-radius: 5px;
  padding-left: 20px;

  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #07224b;
  text-transform: uppercase;
`;
