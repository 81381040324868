import React from 'react';
import styled from 'styled-components';

interface Props {
  value: string;
}

const VerticalDivider = ({ value }: Props) => {
  return <Container value={value} />;
};

const Container = styled.div<{ value: string }>`
  height: ${({ value }) => value};
`;

export default VerticalDivider;
