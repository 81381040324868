import React from 'react';
import * as Yup from 'yup';
import { MdOutlineEmail, MdOutlineLock } from 'react-icons/md';

export const signInSchema = Yup.object({
  email: Yup.string().email('Email inválido').required('Email obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
}).required();

export const signInFields = [
  {
    name: 'email',
    label: 'Endereço de e-mail',
    type: 'input',
    placeholder: 'email@itus.com',
    required: true,
    icon: (props: any) => <MdOutlineEmail {...props} />,
  },
  {
    name: 'password',
    label: 'Sua senha',
    type: 'input',
    placeholder: '********',
    required: true,
    icon: (props: any) => <MdOutlineLock {...props} />,
  },
  {
    name: 'remember',
    label: 'Lembrar de mim por 30 dias',
    type: 'checkbox',
    required: false,
  },
];
