import React from 'react';
import { Switch, Route as RouterRoute, Redirect } from 'react-router-dom';
import NotFoundPage from '../pages/404';
import SignInPage from '../pages/SignInPage';
import StudentPage from '../pages/StudentPage';
import StudentCoursePage from '../pages/StudentCoursePage';

import Route from './Route';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import HomePage from '../pages/HomePage';
import VideoPage from '../pages/VideoPage';
import RankingPage from '../pages/RankingPage';

const Routes = () => (
  <Switch>
    <RouterRoute path="/" exact>
      <Redirect to="/login" />
    </RouterRoute>

    <Route path="/login" component={SignInPage} />

    <Route path="/forgot-password" component={ForgotPasswordPage} />
    <Route path="/reset-password" component={ResetPasswordPage} />

    <Route path="/dashboard" exact isPrivate component={StudentPage} />
    <Route path="/home" exact isPrivate component={HomePage} />
    <Route
      path="/dashboard/attend-course"
      exact
      isPrivate
      component={StudentCoursePage}
    />
    <Route path="/ranking" exact isPrivate component={RankingPage} />
    <Route path="/video" exact isPrivate component={VideoPage} />

    <RouterRoute path="/">{NotFoundPage}</RouterRoute>
  </Switch>
);

export default Routes;
