import React /* { useState, useEffect } */ from 'react';
import { Helmet as Head } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './contexts';
import Routes from './routes';
import GlobalStyles from './global/styles/GlobalStyles';
import ScrollToTop from './components/ScrollToTop';
// import { PageViewSkeleton, VideoViewSkeleton } from './components/Skeleton';
// import useSkeleton from './components/Skeleton/hooks';

const App = () => {
  // const { isLoading, setSkeletonLoading } = useSkeleton();

  // const fontsLoaded = () => {
  //   setSkeletonLoading(false);
  // };
  // useEffect(() => {
  //   window.onload = () => {
  //     fontsLoaded();
  //   };
  // }, []);
  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppProvider>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Cantora+One&display=swap"
          rel="stylesheet"
        />

        <title>ITUS - Plataforma do Aluno</title>
      </Head>

      {/* {isLoading ? (
        <PageViewSkeleton isLoading={isLoading} />
      ) : ( */}
      <BrowserRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
      {/* )} */}

      <GlobalStyles />
    </AppProvider>
  );
};

export default App;
