import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from 'styled-components';
import useToast from '../../hooks/useToast';
import { forgotPasswordFields, forgotPasswordSchema } from './scheme';
import api from '../../services/api';

export const useForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();
  const { addToast } = useToast();
  const { colors } = useTheme();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const onSubmit = useCallback(
    async (form: any) => {
      setIsLoading(true);
      try {
        await api.post('/users/forgot-password', { userEmail: form.email });
        addToast({
          type: 'info',
          title: 'Enviando e-mail de recuperação',
          description: `Estamos enviando um e-mail para o endereço "${form.email}" com instruções para recuperar sua senha. Verifique sua caixa de entrada em alguns instantes.`,
        });

        push('/login');
      } catch (err: any) {
        forgotPasswordFields.forEach(field => {
          setError(field.name, {
            type: 'manual',
            message: err?.response?.data?.error,
          });
        });
        addToast({
          type: 'error',
          title: 'Erro ao enviar e-mail de recuperação',
          description: `Não foi possível enviar um e-mail para o endereço "${form.email}". Verifique se o e-mail digitado está correto`,
        });
        setIsLoading(false);
      }
    },
    [addToast, push, setError],
  );

  const clearAllErrors = useCallback(() => {
    if (errors) clearErrors();
  }, [errors, clearErrors]);

  return {
    clearAllErrors,
    colors,
    errors,
    handleSubmit,
    isLoading,
    isValid,
    onSubmit,
    register,
  };
};
