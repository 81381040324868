import { useCallback, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import api from '../../services/api';

interface ApiClass {
  id: string;
  title: string;
  synchronousClassLink: string | null;
  schoolId: string;
}

const useCoursesContainer = () => {
  const { user } = useAuth();
  const [classes, setClasses] = useState<ApiClass[]>([]);

  const requestClasses = useCallback(async () => {
    const { data } = await api.get<ApiClass[]>(`/students/${user.id}/classes`);
    setClasses(data);
  }, [user.id]);

  useEffect(() => {
    requestClasses();
  }, [requestClasses]);

  return { classes };
};

export default useCoursesContainer;
