import React from 'react';

import styled from 'styled-components';
import StudentMyCoursesList from './components/StudentMyCoursesList';
import StudentRelatedCourses from './components/StudentRelatedCourses';
import StudentInfo from './components/StudentInfo';
import StudentClassCoursesList from './components/StudentClassCoursesList';

import './styles.scss';
import Header from '../../components/Header';

const StudentPage = () => {
  return (
    <Container>
      <Header />
      <main className="page-content">
        <StudentInfo />
        <StudentClassCoursesList />
        {/* <StudentMyCoursesList />
        <StudentRelatedCourses /> */}
      </main>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

export default StudentPage;
