import React, { memo } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import Input from './Input';
import Checkbox from './Checkbox';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<any>;
  label: string;
  type: 'input' | 'checkbox';
  icon?: (props: any) => React.ReactNode;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  clearAllErrors: () => void;
}

const fieldTypes = {
  input: (props: Props) => (
    <Input
      {...props}
      icon={props.icon ? props.icon : prop => <div {...prop} />}
    />
  ),
  checkbox: (props: Props) => <Checkbox {...props} />,
};

const FieldComponent = ({ name = '', type = 'input', ...rest }: Props) =>
  fieldTypes[type]({
    name,
    type,
    ...rest,
  });

const Field = memo(FieldComponent, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});

export default Field;
