import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useAuth from '../../hooks/useAuth';

import NextLessonsComponent from './components/NextLessonsComponent';
import LessonComponent from './components/LessonComponent';
import FeedbackComponent from './components/FeedbackComponent';
import CourseInfo from './components/CourseInfoComponent';
import CourseTeachers from './components/CourseTeachersComponent';
import CourseLessons from './components/CourseLessonsComponent';

import api from '../../services/api';
import CourseVideo from '../../models/CourseVideo';

import './styles.scss';
import NavigationBar from '../../components/NavigationBar';

interface LocationState {
  courseId: string;
  courseName: string;
  courseDescription: string;
}

interface videos {
  videos: CourseVideo[];
}

const StudentCoursePage = () => {
  const { user } = useAuth();

  const [courseVideos, setCourseVideos] = useState<CourseVideo[]>([]);
  const [currentLesson, setCurrentLesson] = useState<CourseVideo>();
  const [currentLessonNumber, setCurrentLessonNumber] = useState(1);

  useEffect(() => {
    async function requestCourseVideos() {
      // const response = await api.get<videos>(`/courses/${courseId}/videos`);
    }
    //   requestCourseVideos();
  }, [user.id]);

  useEffect(() => {
    if (courseVideos && courseVideos.length > 0) {
      setCurrentLesson(courseVideos[currentLessonNumber - 1]);
    }
  }, [courseVideos, currentLessonNumber]);

  const onClickNextLesson = useCallback((lessonNumber: number) => {
    setCurrentLessonNumber(lessonNumber);
  }, []);

  return (
    <>
      <NavigationBar />
      <main className="student-course-page">
        <CourseInfo
          courseTitle="CURSO DE TESTE "
          courseDescription="Descrição do curso de teste"
        />

        <CourseTeachers courseId="1" />
      </main>
    </>
  );
};

export default StudentCoursePage;
