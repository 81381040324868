import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdOutlineInfo, MdOutlineDone } from 'react-icons/md';
import Header from '../../components/Header';
import VideoView from '../../components/VideoView';
import DoubleArrowIcon from '../../components/DoubleArrowIcon';

import useVideoPage from './hooks';
import Feedback from '../../components/Feedback';
import Teachers from '../../components/Teachers';
import Button from '../../components/Button';
import LessonAttachment from '../../components/Attachment';
import useLessons from '../../hooks/data/useLessons';

const VideoPage = () => {
  const {
    colors,
    courseId,
    courseName,
    video,
    handleSubmit,
    onSubmit,
    register,
    user,
    teachers,
    setValue,
    quiz,
  } = useVideoPage();

  const { completeLesson, checkIsLessonCompleted } = useLessons(courseId);
  const [lessonId, setLessonId] = useState<string>(video.id);
  const [isLesson, setIsLesson] = useState<boolean>(true);
  const onChangeLesson = async (idLesson: string) => {
    setLessonId(idLesson);
    setIsLesson(true);
  };

  const onChangeQuiz = async (idQuiz: string) => {
    setIsLesson(false);
  };

  const [isCompleted, setIsLessonCompleted] = useState<boolean | undefined>(
    undefined,
  );

  const isLessonCompleted = async () => {
    if (user.id && video.id) {
      const completed = await checkIsLessonCompleted(user.id, video.id);
      setIsLessonCompleted(completed);
    }
  };
  const handleCompleteLesson = async () => {
    if (video.id) {
      await completeLesson(user.id, video.id);
    }
  };

  useEffect(() => {
    isLessonCompleted();
  }, [video.id]); // eslint-disable-line

  return (
    <Container>
      <Header />
      <Wrapper>
        <Title>
          <p>ItusHub</p>
          {courseName && (
            <>
              <DoubleArrowIcon size={24} color={colors.primary} />
              <p>{courseName}</p>
            </>
          )}
          <DoubleArrowIcon size={24} color={colors.primary} />
          <p>{isLesson ? video.title : quiz.title}</p>
        </Title>
        <VideoWrapper>
          <VideoView
            quiz={quiz}
            video={video}
            courseId={courseId}
            courseName={courseName}
            onChangeLesson={onChangeLesson}
            onChangeQuiz={onChangeQuiz}
          />
        </VideoWrapper>
        <CompleteLesson>
          <div className="wrapper-button">
            {isCompleted !== undefined && isLesson && (
              <Button
                onClick={handleCompleteLesson}
                icon={MdOutlineDone}
                disabled={isCompleted}
                title={
                  isCompleted
                    ? 'Você já concluiu esta aula'
                    : 'Marcar aula como concluída'
                }
                value={isCompleted ? 'Concluído' : 'Marcar como concluído'}
              />
            )}
          </div>
        </CompleteLesson>
        <Title>Assunto</Title>
        <Description>{video.description}</Description>
        {video.assignmentUri && (
          <Link href={video.assignmentUri} target="_blank" rel="noreferrer">
            <div className="wrapper-button">
              <Button
                icon={MdOutlineInfo}
                backgroundColor="secondaryDark"
                value="Link para atividade"
              />
            </div>
          </Link>
        )}

        <LessonAttachment lessonId={lessonId} />

        {teachers.length > 0 && (
          <>
            <Title>Professores</Title>
            <Teachers teachers={teachers} />
          </>
        )}
        {courseName && (
          <>
            <Title>Avaliação</Title>
            <WrapperFeedback onSubmit={handleSubmit(onSubmit)}>
              <Feedback
                setValue={setValue}
                register={register}
                lessonId={video.id}
              />
              <img src="/assets/ilustracao-itus-02.png" alt="Ilustração" />
            </WrapperFeedback>
          </>
        )}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Wrapper = styled.div`
  z-index: 1;
  padding: calc(10vh) 0 0 0;
  max-width: 920px;
  margin: 0 auto;
  padding: 10vh 1rem 0;
`;

const CompleteLesson = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  & .wrapper-button {
    margin: 1rem auto;
    max-width: 250px;
    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
    & button {
      padding: 0.5rem 2rem;
    }
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.themedTitle};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
  & p {
    margin: 0 1rem;

    &:nth-child(-n + 5) {
      font-size: ${({ theme }) => theme.fontSizes.sm};
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    /* font-size: ${({ theme }) => theme.fontSizes.sm}; */
    & p {
      margin: 0 0.5rem;
    }
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Description = styled.p`
  padding: 0 1rem;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.themedTitle};
  margin-top: 1rem;
`;

const Link = styled.a`
  text-decoration: none;
  & .wrapper-button {
    padding: 1rem 1rem 0;
    max-width: 250px;
    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }
`;

const WrapperFeedback = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 2.5rem;

  & img {
    width: 360px;
    margin-left: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    & img {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
`;

export default VideoPage;
