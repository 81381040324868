import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

import SectionHeader from '../../../../components/SectionHeader';
import EmptySection from '../../../../components/EmptySection';

import { CourseTeachersComponent, TeacherInfo } from './styles';

interface CourseTeacherProps {
  courseId: String;
}

interface Profile {
  id: string;
  name: string;
  email: string;
}

interface Teacher {
  profile: Profile;
}

const CourseTeachers = ({ courseId }: CourseTeacherProps) => {
  const [courseTeachers, setCourseTeachers] = useState<Teacher[]>([]);

  useEffect(() => {
    async function requestCourseTeachers() {
      const { data } = await api.get<Teacher[]>(
        `/teachers/from-course/${courseId}`,
      );

      setCourseTeachers(data.map((teacher, index) => ({ ...teacher })));
    }
    // requestCourseTeachers();
  }, [courseId]);

  return (
    <CourseTeachersComponent>
      <SectionHeader>
        <h1>Professores</h1>
      </SectionHeader>
      {courseTeachers.length === 0 && (
        <EmptySection>
          <p>Este Curso não tem professores vinculados</p>
        </EmptySection>
      )}
      {courseTeachers.length > 0 &&
        courseTeachers.map((teacher, index) => (
          <TeacherInfo>{teacher.profile.name}</TeacherInfo>
        ))}
      {/*  <section id="course-teachers">
        <h1 className="typography-sans">Professores</h1>
        <section id="teachers" className="flex-collumn">
          <div
            id="teacher-1"
            className="flex-row teacher-container justify-space align-center"
          >
            <div className="teacher-info flex-row align-center">
              <img
                src="https://api.multiavatar.com/Girafales.svg"
                alt="Avatar"
                className="teacher-photo"
              />
              <p className="typography-sans">
                Este curso não tem nenhum professor vinculado ...
              </p>
            </div>
          </div>
          <div
            id="teacher-2"
            className="flex-row teacher-container justify-space align-center"
          >
            <div className="teacher-info flex-row align-center">
              <img
                src="https://api.multiavatar.com/Florinda.svg"
                alt="Avatar"
                className="teacher-photo"
              />
              <p className="typography-sans">
                Este curso não tem nenhum professor vinculado...
              </p>
            </div>
          </div>
        </section>
      </section> */}
    </CourseTeachersComponent>
  );
};

export default CourseTeachers;
