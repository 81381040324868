import React, { useEffect, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<any>;
  label: string;
  icon: (props: any) => React.ReactNode;
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  clearAllErrors: () => void;
}

const Input = ({
  register,
  label,
  placeholder,
  name = '',
  required,
  icon = () => <MdOutlineVisibility />,
  error,
  clearAllErrors,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isSecure, setIsSecure] = useState(name === 'password');
  const { colors } = useTheme();

  const isPasswordInput = name.toLowerCase().includes('password');

  const handleSecure = () => setIsSecure(!isSecure);

  useEffect(() => {
    if (isFocused && !!error) clearAllErrors();
  }, [error, clearAllErrors, isFocused]);

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <InputContainer error={error}>
        <WrapperIcon>{icon({ color: colors.icon, size: 22 })}</WrapperIcon>
        <FormInput
          {...register(name, { required })}
          id={name}
          placeholder={placeholder}
          type={isSecure ? 'password' : 'text'}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {isPasswordInput && (
          <WrapperSecure onClick={handleSecure}>
            {isSecure ? (
              <MdOutlineVisibility color={colors.icon} size={22} />
            ) : (
              <MdOutlineVisibilityOff color={colors.icon} size={20} />
            )}
          </WrapperSecure>
        )}
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};

  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 0.5rem;
`;

const InputContainer = styled.div<{
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}>`
  transition: border 0.3s ease-in-out;
  border: ${({ error, theme }) =>
    error
      ? `2px solid ${theme.colors.alert}`
      : `2px solid ${theme.colors.opacityLight}`};
  border-radius: 0.25rem;
  position: relative;
`;

const WrapperIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperSecure = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0.5rem;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityButtonInput};
  }
`;

const FormInput = styled.input`
  color: ${({ theme }) => theme.colors.input};
  background-color: ${({ theme }) => theme.colors.opacityLight};

  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSizes.sm};

  border: none;
  padding: 0.4rem 2.5rem;

  width: 100%;

  &:focus {
    outline: none;
  }
`;

export default Input;
