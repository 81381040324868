import React, { useCallback } from 'react';
import { MdLogout } from 'react-icons/md';
import useAuth from '../../../../hooks/useAuth';
import api from '../../../../services/api';

import { LogoutButton, RedirectToCustomerPortalButton } from './styles';

const UserMenu = () => {
  const { user, signOut } = useAuth();

  const handleCustomerPortalRedirection = useCallback(async () => {
    const { status, data } = await api.post(
      '/payments/create-customer-portal-session',
      {
        userProfileId: user.profile.id,
      },
    );

    if (status === 201) {
      window.location.replace(data.portalSessionUrl);
    }
  }, [user.profile.id]);

  return (
    <div>
      <LogoutButton type="button" onClick={signOut}>
        Log Out
        <MdLogout size={24} className="logout-icon" />
      </LogoutButton>
    </div>
  );
};

export default UserMenu;
