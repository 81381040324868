import styled from 'styled-components';
import ContentWrapper from '../ContentWrapper';
import BurguerMenu from './components/BurguerMenu';

export const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  top: 0;

  height: 80px;
  width: 100%;

  background-color: var(--color-dark-green);

  z-index: 10;
`;

export const NavBarContent = styled(ContentWrapper)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  max-width: 1120px;

  .itus-logo {
    width: 64px;
  }

  .itus-logo:hover {
    cursor: pointer;
  }
`;

export const NavBarBurguerMenu = styled(BurguerMenu)`
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const UserContainer = styled.div`
  position: relative;
`;

export const UserInformation = styled.article`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 264px;

  &:hover {
    cursor: pointer;
  }

  .user-name {
    font-family: var(--font-sans);
    font-size: 1.25rem;
    color: var(--color-white);

    margin-right: 16px;
  }

  .user-avatar {
    max-width: 64px;
    aspect-ratio: 1 / 1;
  }
`;

export const UserMenuContainer = styled.div<{ shouldShow: boolean }>`
  position: absolute;
  right: 0;

  padding: 24px;
  margin-top: 8px;
  border-radius: 8px;
  background-color: var(--color-light-green);

  transform: scale(${({ shouldShow }) => (shouldShow ? 1 : 0)});
  transform-origin: top right;
  transition: transform 0.1s ease-in;
`;

export const Vanishable = styled.div<{ at: number }>`
  display: none;

  @media screen and (min-width: ${props => props.at}px) {
    display: block;
  }
`;
