import React from 'react';
import * as Yup from 'yup';
import { MdOutlineEmail } from 'react-icons/md';

export const forgotPasswordSchema = Yup.object({
  email: Yup.string().email('Email inválido').required('Email obrigatório'),
}).required();

export const forgotPasswordFields = [
  {
    name: 'email',
    label: 'Endereço de e-mail',
    type: 'input',
    placeholder: 'email@itus.com',
    required: true,
    icon: (props: any) => <MdOutlineEmail {...props} />,
  },
];
