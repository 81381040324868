import React from 'react';
import { UseFormRegister, FieldErrorsImpl } from 'react-hook-form';
import styled from 'styled-components';
import { IconType } from 'react-icons';
import Field from './Field';

export interface InputProps {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  required: boolean;
  icon?: IconType;
}

type Props = {
  register: UseFormRegister<any>;
  dataFields: InputProps[];
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  clearAllErrors: () => void;
};

const Form = ({ register, dataFields, errors, clearAllErrors }: Props) => {
  return (
    <Container>
      {dataFields.map((input: any) => (
        <Field
          error={errors[input.name]}
          key={input.name}
          register={register}
          clearAllErrors={clearAllErrors}
          {...input}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  div {
    margin-bottom: 0.8rem;
    div:last-child {
      margin-bottom: 0rem;
    }
  }
`;

export default Form;
