import React, { useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<any>;
  label: string;
}

const Checkbox = ({
  register,
  label,
  placeholder,
  name = '',
  required,
}: Props) => {
  return (
    <Container htmlFor={name}>
      <FormCheckbox
        {...register(name, { required })}
        id={name}
        placeholder={placeholder}
        type="checkbox"
      />
      <Checkmark className="checkmark" />
      <Label>{label}</Label>
    </Container>
  );
};

const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 5.65px;
    top: 2.5px;
    width: 5px;
    height: 10px;
    border: solid #269912;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Label = styled.div`
  height: 1rem;
  display: flex;
  align-items: center;

  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSizes.xs};

  color: ${({ theme }) => theme.colors.subTitle};
  margin-left: 1.5rem;
`;

const FormCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.opacityLight};
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: #ffffff44;
  border-radius: 0.2rem;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export default Checkbox;
