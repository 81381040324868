import { useCallback, useState } from 'react';

const useSkeleton = () => {
  const [isLoading, setIsLoading] = useState(true);

  const setSkeletonLoading = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { isLoading, setSkeletonLoading };
};

export default useSkeleton;
