import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/Button';
import Form from '../../components/Form';
import VerticalDivider from '../../components/VerticalDivider';
import { signInFields } from './scheme';
import { useSignInPage } from './hooks';

const SignInPage = () => {
  const {
    clearAllErrors,
    errors,
    handleSubmit,
    isValid,
    isLoading,
    onSubmit,
    register,
  } = useSignInPage();

  return (
    <Container>
      <Wrapper>
        <LogoContainer>
          <img
            height={100}
            width={100}
            src="/assets/itus_redesign.svg"
            alt="Logo"
          />
          <Title>Itus Hub</Title>
        </LogoContainer>
        <SubTitle>Faça login e comece a usar!</SubTitle>
        <Form
          register={register}
          dataFields={signInFields}
          errors={errors}
          clearAllErrors={clearAllErrors}
        />
        <VerticalDivider value="1rem" />
        <Button
          isLoading={isLoading}
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        />
        <StyledLink to="/forgot-password">Esqueci minha senha</StyledLink>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgb(7, 34, 75);
  background: linear-gradient(
    45deg,
    rgba(7, 34, 75, 1) 0%,
    rgba(7, 34, 75, 1) 20%,
    rgba(38, 153, 18, 1) 100%
  );
`;

const Wrapper = styled.div`
  max-width: 1920px;
  min-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2rem 5rem;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    min-width: auto;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    padding: 1.5rem 2rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  font-family: 'Inter', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.lg};

  margin-top: 0.5rem;
`;

const SubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.subTitle};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSizes.md};

  margin: 0.75rem 0 1rem 0;
  text-align: center;
`;

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.subTitle};
  transition: 0.3s ease-in-out;
  margin-top: 0.5rem;

  &:hover {
    filter: brightness(0.8);
  }
`;

export default SignInPage;
