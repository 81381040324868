import { useCallback, useEffect, useState } from 'react';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import api from '../../services/api';
import Attachment from '../../models/Attachment';
import { useS3Client } from '../../services/aws';

const useAttachment = (lessonId: string) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const { aws, config } = useS3Client();

  const loadAttachments = useCallback(async () => {
    const { data } = await api.get(`/attachments/get-by-lesson/${lessonId}`);
    setAttachments(data);
  }, [lessonId]);

  const downloadAttachment = useCallback(
    async (key: string) => {
      const getObjectParams = {
        Bucket: config.AWS_S3_BUCKET,
        Key: key,
      };

      try {
        const response = await aws.send(new GetObjectCommand(getObjectParams));
        const byteArray = await response.Body?.transformToByteArray();
        const blob = byteArray
          ? new Blob([byteArray], {
              type: `application/${key.split('.')[1]}`,
            })
          : null;
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', key);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.error('Erro ao baixar o arquivo:', error); // eslint-disable-line
      }
    },
    [], // eslint-disable-line
  );

  useEffect(() => {
    lessonId && loadAttachments();
  }, [lessonId]); // eslint-disable-line
  return { attachments, downloadAttachment };
};

export default useAttachment;
