import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import Button from '../../components/Button';
import Form from '../../components/Form';
import VerticalDivider from '../../components/VerticalDivider';
import { resetPasswordFields } from './scheme';
import { useResetPasswordPage } from './hooks';

const ResetPasswordPage = () => {
  const {
    clearAllErrors,
    colors,
    errors,
    handleSubmit,
    isValid,
    isLoading,
    onSubmit,
    register,
  } = useResetPasswordPage();

  return (
    <Container>
      <Wrapper>
        <StyledLink to="/login">
          <MdKeyboardArrowLeft size={30} color={colors.title} />
        </StyledLink>
        <LogoContainer>
          <img
            height={100}
            width={100}
            src="/assets/itus_redesign.svg"
            alt="Logo"
          />
          <Title>Itus Hub</Title>
        </LogoContainer>
        <SubTitle>Redefine sua senha aqui!</SubTitle>
        <SubTitle>
          Digite e confirme sua nova senha para <br /> redefinição de senha.
        </SubTitle>

        <Form
          register={register}
          dataFields={resetPasswordFields}
          errors={errors}
          clearAllErrors={clearAllErrors}
        />
        <VerticalDivider value="1rem" />
        <Button
          isLoading={isLoading}
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
          value="Recuperar senha"
        />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgb(7, 34, 75);
  background: linear-gradient(
    45deg,
    rgba(7, 34, 75, 1) 0%,
    rgba(7, 34, 75, 1) 20%,
    rgba(38, 153, 18, 1) 100%
  );
`;

const Wrapper = styled.div`
  max-width: 1920px;
  min-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 2rem 5rem;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    min-width: auto;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    padding: 1.5rem 2rem;
  }
`;

const StyledLink = styled(Link)`
  position: absolute;
  left: 2rem;
  top: 2rem;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityButton};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  font-family: 'Inter', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.lg};

  margin-top: 0.5rem;
`;

const SubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.subTitle};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSizes.md};

  margin: 0.75rem 0 1rem 0;
  text-align: center;
`;

export default ResetPasswordPage;
