import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import useAuth from '../../hooks/useAuth';
import useToast from '../../hooks/useToast';
import { signInFields, signInSchema } from './scheme';

export const useSignInPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { signIn } = useAuth();
  const { push } = useHistory();
  const { addToast } = useToast();
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(signInSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const handleApiError = useCallback(
    (errorCode: string, error?: Error) => {
      switch (errorCode) {
        case 'AUTH_EMAIL_NOT_REGISTERED': {
          addToast({
            type: 'error',
            title: 'E-mail inválido',
            description: 'O e-mail informado não está cadastrado',
          });

          break;
        }

        case 'AUTH_PASSWORD_DOES_NOT_MATCH': {
          addToast({
            type: 'error',
            title: 'Senha inválida',
            description: 'A senha informada não confere com o e-mail informado',
          });

          break;
        }

        default: {
          addToast({
            type: 'error',
            title: 'Oops...',
            description:
              'Ocorreu um erro inesperado, por favor tente novamente mais tarde. Se o error persistir entre em contato conosco.',
          });

          break;
        }
      }
    },
    [addToast],
  );

  const onSubmit = useCallback(
    async (form: any) => {
      setIsLoading(true);
      try {
        await signIn({
          email: form.email,
          password: form.password,
        });
        push('/home');
      } catch (err: any) {
        handleApiError(err?.response?.data?.code, err);

        signInFields.forEach(field => {
          setError(field.name, {
            type: 'manual',
            message: err?.response?.data?.error,
          });
        });
        setIsLoading(false);
      }
    },
    [push, signIn, handleApiError, setError],
  );

  const clearAllErrors = useCallback(() => {
    if (errors) clearErrors();
  }, [errors, clearErrors]);

  return {
    clearAllErrors,
    errors,
    handleSubmit,
    isLoading,
    isValid,
    onSubmit,
    register,
  };
};
