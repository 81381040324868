import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import useCoursesContainer from '../../components/ClassesContainer/hooks';
import { RankingMember } from '../../models/RankingMember';

const useRankingPage = () => {
  const { classes } = useCoursesContainer();
  const [school] = classes || [];

  const [ranking, setRanking] = useState<RankingMember[]>([]);

  const requestClassesRanking = useCallback(async () => {
    if (!school.schoolId) return;
    const { data } = await api.get<RankingMember[]>(
      `/classes/ranking/${school.schoolId}`,
    );
    setRanking(data);
  }, [school]);

  useEffect(() => {
    school?.schoolId && requestClassesRanking();
  }, [school]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ranking,
  };
};

export default useRankingPage;
