import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type QueryParams = Record<string, string>;

export default function useQueryParams(): QueryParams {
  const { search } = useLocation();

  const query = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const result: QueryParams = {};

    for (const [key, value] of urlSearchParams.entries()) {
      result[key] = value;
    }

    return result;
  }, [search]);

  return query;
}
