import React, { useEffect, useMemo, useState } from 'react';
import api from '../services/api';
import useAuth from './useAuth';

interface StudentAvatar {
  avatarUri: string;
}

export const useAvatarUrl = () => {
  const { user } = useAuth();
  const [avatar, setAvatar] = useState<StudentAvatar>();

  useEffect(() => {
    async function resetAvatar() {
      const { data } = await api.get<StudentAvatar>(`/students/${user.id}`);
      setAvatar(data);
    }

    resetAvatar();
  }, [user.id]);

  const userAvatarUrl = useMemo(() => {
    const avatarToken = avatar?.avatarUri
      ? avatar.avatarUri
      : user.profile.name;
    return `https://api.multiavatar.com/${avatarToken}.svg`;
  }, [avatar, user.profile.name]);

  return { userAvatarUrl };
};
