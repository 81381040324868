import React from 'react';

import { EmptySectionText } from './styles';

interface EmptySectionProps {
  children: React.ReactNode;
}

const EmptySection = ({ children }: EmptySectionProps) => {
  return (
    <EmptySectionText>
      {React.Children.map(children, (child, index) => {
        /*
         * Checks if the component is valid (avoiding typescript problems) and
         * if the component is some variant of a header (h1, h2, h3 etc.). This
         * way anything else (like icons and such) doesn't get targeted by rules
         * intended to be applied on text.
         */
        if (React.isValidElement(child)) {
          return React.cloneElement(child);
        }
        return child;
      })}
    </EmptySectionText>
  );
};

export default EmptySection;
