import { useContext } from 'react';
import { ThemeContext } from '../contexts/ThemeContext';

export default function useAopTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useAppTheme must be used within a ThemeContext');
  }

  return context;
}
