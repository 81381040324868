import styled from 'styled-components';

interface MenuComponentProps {
  shouldShow: boolean;
}

export const BurguerMenuButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;

  .burguer-menu-bar {
    width: 24px;
    height: 2px;

    background-color: var(--color-white);
    border-radius: 2px;

    :not(:last-child) {
      margin-bottom: 6px;
    }
  }
`;

export const Menu = styled.menu<MenuComponentProps>`
  display: ${props => (props.shouldShow ? 'flex' : 'none')};
  flex-direction: column;

  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;

  background-color: var(--color-white);

  .close-button {
    align-self: flex-end;
    background-color: inherit;
    border: none;
    outline: none;

    margin-bottom: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
