import React from 'react';
import styled, { keyframes } from 'styled-components';

export const VideoViewSkeleton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <SkeletonContainer isLoading={isLoading}>
      <VideoSkeleton />
      <LessonSkeleton />
    </SkeletonContainer>
  );
};

export const PageViewSkeleton = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <SkeletonContainer isLoading={isLoading} direction="column" gap="0.1rem">
      <ViewSkeleton />
      <ViewSkeleton />
      <ViewSkeleton />
    </SkeletonContainer>
  );
};

export const RankingSkeleton = ({
  isLoading,
  size = 3,
}: {
  isLoading: boolean;
  size: number;
}) => {
  return (
    <SkeletonContainer isLoading={isLoading} direction="column" gap="0.5rem">
      <ViewSkeleton size="4rem" />
      {[...Array(size)].map((_, index) => (
        <ViewSkeleton key={index} size="3rem" /> // eslint-disable-line
      ))}
    </SkeletonContainer>
  );
};

const pulse = keyframes`
  0% {
    background-color: rgba(50, 50, 50, 0.1);
  }
  20% {
    background-color: rgba(70, 70, 70, 0.1);
  }
  40% {
    background-color: rgba(90, 90, 90, 0.1);
  }
  60% {
    background-color: rgba(110, 110, 110, 0.1);
  }
  80% {
    background-color: rgba(90, 90, 90, 0.1);
  }
  100% {
    background-color: rgba(70, 70, 70, 0.1);
  }
`;

const ViewSkeleton = styled.div<{ size?: string }>`
  border-radius: 16px;
  width: 100%;
  height: ${({ size }) => size || '30vh'};
  animation: ${pulse} 1.5s infinite;
`;

const LessonSkeleton = styled.div`
  border-radius: 16px;
  width: 27.5%;
  animation: ${pulse} 1.5s infinite;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const VideoSkeleton = styled.div`
  gap: 1rem;
  border-radius: 16px;
  width: 72.5%;
  animation: ${pulse} 1.5s infinite;
`;

const SkeletonContainer = styled.div<{
  isLoading?: boolean;
  direction?: 'row' | 'column';
  justify?: 'center' | 'flex-start' | 'flex-end';
  gap?: string;
}>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  margin: 0.4rem 0;
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: ${({ gap }) => gap || '0.5rem'};
  justify-content: ${({ justify }) => justify || 'center'};
  min-width: 55rem;
  min-height: 22rem;
`;
