import React from 'react';
import Modal from 'react-modal';
import styled, { useTheme } from 'styled-components';
import { MdClose } from 'react-icons/md';
import Button from '../../components/Button';
import VerticalDivider from '../../components/VerticalDivider';

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
};

interface Props {
  isOpen: boolean;
  infos: {
    isDebut?: boolean;
    title?: string;
    image?: string;
    flag?: string;
    description?: string;
  };
  toogleModal: () => void;
  onClickPlay: () => void;
}

const MoreInformationModal = ({
  isOpen,
  infos,
  toogleModal,
  onClickPlay,
}: Props) => {
  const { colors } = useTheme();

  return (
    <Container style={customStyles} isOpen={isOpen}>
      <Wrapper>
        <CloseButton onClick={toogleModal}>
          <MdClose size={32} color={colors.themedTitle} />
        </CloseButton>
        <Image src={infos.image} alt="Imagem do vídeo" />
        <Title src={infos.title} alt="Título do vídeo" />
        <Description>{infos.description}</Description>
        <VerticalDivider value="3rem" />
        <Button value="Assistir!" onClick={onClickPlay} />
      </Wrapper>
    </Container>
  );
};

const Container = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    align-items: flex-end;
  }
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 3rem 3.5rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.background};

  @media screen and (max-width: 768px) {
    max-width: 100%;
    border-radius: 1rem 1rem 0 0;
    width: 100%;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  border-radius: 0.3rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityIcon};
  }
`;

const Image = styled.img`
  width: 30rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.dark};
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const Title = styled.img`
  width: 15rem;
  margin-top: 3rem;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.themedTitle};
  margin-top: 3rem;
`;

export default MoreInformationModal;
