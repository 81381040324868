import React from 'react';
import styled from 'styled-components';
import CoursesContainer from '../CoursesContainer';
import useCoursesContainer from './hooks';

const ClassesContainerComponent = () => {
  const { classes } = useCoursesContainer();
  return (
    <Container>
      {classes.map(item => (
        <CoursesContainer classCourse={item} key={item.id} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding-top: 1rem;
`;

const ClassesContainer = React.memo(
  ClassesContainerComponent,
  (prevProps, nextProps) => {
    return Object.is(prevProps, nextProps);
  },
);

export default ClassesContainer;
