import React, { ButtonHTMLAttributes } from 'react';
import { MdOutlineMotionPhotosOn } from 'react-icons/md';
import styled, { keyframes, DefaultTheme, css } from 'styled-components';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  backgroundColor?: keyof DefaultTheme['colors'];
  icon?: (props: any) => JSX.Element;
}

const Button = ({
  value = 'Entrar na Plataforma',
  isLoading = false,
  backgroundColor = 'primary',
  icon,
  ...rest
}: Props) => {
  return (
    <StyledButton
      backgroundColor={backgroundColor}
      type="submit"
      value={value}
      {...rest}
    >
      {icon && <Icon>{icon({ size: 24, color: 'white' })}</Icon>}
      {!isLoading ? value : <Loading size={24} />}
    </StyledButton>
  );
};

const buttonAnimation = () => keyframes`
  0% { width: 0; color: transparent }
  50% { color: auto }
  100% { width: 100% }
`;

const loadingAnimation = () => keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledButton = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 0.25rem;

  background-color: ${({ theme, backgroundColor }) =>
    theme.colors[backgroundColor!]};
  color: ${({ theme }) => theme.colors.title};
  font-family: ${({ theme }) => theme.fonts.inter};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: 0.5rem 0;
  width: 100%;

  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;

  -webkit-animation: ${props => buttonAnimation} 1.5s ease-out forwards;
  animation: ${props => buttonAnimation} 1.5s ease-out forwards;

  transition: all 0.6s ease-in-out;

  ${({ disabled, theme }) =>
    disabled
      ? css`
          background-color: ${theme.colors.disabledBackground};
          color: ${theme.colors.disabledColor};
        `
      : css`
          &:hover {
            cursor: pointer;
            filter: brightness(0.8);
          }
          opacity: 1;
        `};
`;

const Icon = styled.div`
  width: 24px;
  height: 100%;
  margin-right: 0.3rem;
`;

const Loading = styled(MdOutlineMotionPhotosOn)`
  margin: 0 auto;
  animation: ${props => loadingAnimation} 1.5s ease-out infinite;
`;

export default Button;
