import React, { createContext, useCallback, useMemo, useState } from 'react';
import { errorHandlerMap, ErrorHandlerMap } from '../errors/errorHandlerMap';
import useToast from '../hooks/useToast';

export interface ErrorsContextState {
  handleApplicationError: (errorCode: string, error?: Error) => Promise<void>;
  handleApiError: (errorCode: string, error?: Error) => void;
}

export const ErrorsContext = createContext<ErrorsContextState>(
  {} as ErrorsContextState,
);

export const ErrorsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { addToast } = useToast();
  const [errorMap] = useState<ErrorHandlerMap>(errorHandlerMap);

  const handleApplicationError = useCallback(
    async (errorCode: string, error?: Error) => {
      if (typeof errorMap[errorCode] === 'function')
        await errorMap[errorCode](error);
      else throw new Error(`Error handler for ${errorCode} not found.`);
    },
    [errorMap],
  );

  const handleApiError = useCallback(
    (errorCode: string, error?: Error) => {
      switch (errorCode) {
        case 'AUTH_EMAIL_NOT_REGISTERED': {
          addToast({
            type: 'error',
            title: 'e-Mail inválido',
            description: 'O e-Mail informado não está cadastrado',
          });

          break;
        }

        case 'AUTH_PASSWORD_DOES_NOT_MATCH': {
          addToast({
            type: 'error',
            title: 'Senha inválida',
            description: 'A senha informada não confere com o e-Mail informado',
          });

          break;
        }

        default: {
          addToast({
            type: 'error',
            title: 'Oops...',
            description:
              'Ocorreu um erro inesperado, por favor tente novamente mais tarde. Se o error persistir entre em contato conosco.',
          });

          break;
        }
      }
    },
    [addToast],
  );

  const providerValue = useMemo(
    () => ({ handleApplicationError, handleApiError }),
    [handleApiError, handleApplicationError],
  );

  return (
    <ErrorsContext.Provider value={providerValue}>
      {children}
    </ErrorsContext.Provider>
  );
};
