export const darkTheme = {
  colors: {
    background: '#121214',
    backgroundSecondary: '#1B1B1F',
    backgroundTertiary: '#ffffff33',
    title: '#fff',
    text: '#d4d4d9',
    subTitle: '#E1E1E6',
    primary: '#269912',
    primaryDark: '#1f7d0f',
    primaryLight: '#67B759',
    primaryOpacity: '#26991377',
    primaryOpacityLight: '#26991255',
    secondary: '#2782C4',
    secondaryDark: '#1B5B89',
    icon: '#333',
    input: '#444',
    opacityLight: '#ffffffdd',
    opacityButton: '#ffffff55',
    opacityButtonInput: '#00000033',
    opacityIcon: '#ffffff22',
    disabledBackground: '#cccccc',
    disabledColor: '#666666',
    moreInformationButton: '#7C7C8A',
    alert: '#D61C4E',
    header: '#181818',
    themedTitle: '#fff',
    dark: '#181818',
  },
  fonts: { inter: 'Inter', cantora: 'Cantora One' },
  fontSizes: {
    xs: '0.8rem',
    sm: '1.0rem',
    md: '1.5rem',
    lg: '2.5rem',
    xl: '4.5rem',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

export const lightTheme = {
  colors: {
    background: '#f0f2f5',
    backgroundSecondary: '#e5e5e5',
    backgroundTertiary: '#ccc8c8',
    title: '#fff',
    text: '#7d7d80',
    subTitle: '#E1E1E6',
    primary: '#269912',
    primaryDark: '#1f7d0f',
    primaryLight: '#00c800  ',
    primaryOpacity: '#269912dd',
    primaryOpacityLight: '#26991277',
    secondary: '#2782C4',
    secondaryDark: '#07224B',
    icon: '#333',
    input: '#444',
    opacityLight: '#ffffffdd',
    opacityButton: '#ffffff55',
    opacityButtonInput: '#00000033',
    opacityIcon: '#00000033',
    disabledBackground: '#cccccc',
    disabledColor: '#666666',
    moreInformationButton: '#7C7C8A',
    alert: '#D61C4E',
    header: '#269912',
    themedTitle: '#222',
    dark: '#181818',
  },
  fonts: { inter: 'Inter', cantora: 'Cantora One' },
  fontSizes: {
    xs: '0.8rem',
    sm: '1.0rem',
    md: '1.5rem',
    lg: '2.5rem',
    xl: '4.5rem',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

export default darkTheme;
