import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import StopIcon from '@mui/icons-material/Stop';
import { Question, Quiz } from '../../models/Quiz';
import useToast from '../../hooks/useToast';
import useQuiz from '../../hooks/data/useQuiz';
import QuestionsAnswers from './QuestionsAnswers';

type QuizComponentProps = {
  quiz: Quiz;
};

const QuizComponent = ({ quiz }: QuizComponentProps) => {
  const [listening, setListening] = useState(false);
  const [answers, setAnswers] = useState<{ [questionId: string]: string }>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const { addToast } = useToast();
  const { completeQuiz } = useQuiz();
  const currentQuestion = quiz.questions[currentQuestionIndex];

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers({
      ...answers,
      [currentQuestion.id]: event.target.value,
    });
  };

  const handleNext = () => {
    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = async () => {
    const answerLength = Object.keys(answers).length;
    if (answerLength < quiz.questions.length) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar Quiz',
        description: 'Você deve responder todas as perguntas!',
      });
      return;
    }
    await completeQuiz(quiz.id, answers);
  };

  const [canDoQuiz, setCanDoQuiz] = useState<boolean>(false);
  const [seeQuestionsAnswers, setSeeQuestionsAnswers] =
    useState<boolean>(false);

  const handleStartQuiz = () => {
    if (quiz.attempts === 0 || quiz.attempts < 2) {
      if (quiz.note === 100) {
        setSeeQuestionsAnswers(true);
        return;
      }
      setCanDoQuiz(true);
      return;
    }
    setSeeQuestionsAnswers(true);
  };

  const handleListenQuestion = (question: Question) => {
    setListening(!listening);
    if (listening) {
      window.speechSynthesis.cancel();
      return;
    }

    const splitText = (text: string, maxLength: number) => {
      let result = text || '';
      const parts = [];
      while (result.length > maxLength) {
        let splitIndex = result.lastIndexOf('.', maxLength);
        if (splitIndex === -1) {
          splitIndex = maxLength;
        }
        parts.push(result.substring(0, splitIndex + 1));
        result = result.substring(splitIndex + 1).trim();
      }
      if (result.length > 0) {
        parts.push(result);
      }
      return parts;
    };

    const text = `${question.description}...
    Alternativa A: ${question.optionA}...
    Alternativa B: ${question.optionB}...
    Alternativa C: ${question.optionC}...
    Alternativa D: ${question.optionD}
  `;

    const parts = splitText(text, 120);

    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
      let currentIndex = 0;

      const speakNextPart = () => {
        if (currentIndex < parts.length) {
          const utterance = new SpeechSynthesisUtterance(parts[currentIndex]);
          utterance.lang = 'pt-BR';
          utterance.rate = 1;
          utterance.pitch = 1;

          utterance.onend = () => {
            currentIndex += 1;
            speakNextPart();
          };

          window.speechSynthesis.speak(utterance);
          return;
        }
        setListening(false);
      };
      speakNextPart();
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao falar',
        description: 'Seu navegador não suporta a funcionalidade de fala',
      });
    }
  };

  useEffect(() => {
    window.speechSynthesis.cancel();
    setAnswers({});
    setCurrentQuestionIndex(0);
  }, [quiz.id]);
  const getQuizButtonText = () => {
    if (quiz.attempts === 0) {
      return 'Começar Quiz';
    }
    if (quiz.attempts < 2) {
      return quiz.note === 100 ? 'Ver Respostas' : 'Tentar novamente';
    }

    return 'Ver Respostas';
  };

  return (
    <QuizContainer>
      {currentQuestion && quiz.attempts < 2 && canDoQuiz ? (
        <>
          <Progress>
            <p>
              {currentQuestionIndex + 1} / {quiz.questions.length}
            </p>
          </Progress>
          <QuestionDescriptionContainer>
            <ListenQuestion>
              <ListButton
                type="button"
                onClick={() =>
                  handleListenQuestion(currentQuestion as Question)
                }
              >
                {listening ? (
                  <StopIcon fontSize="small" />
                ) : (
                  <VolumeUpIcon fontSize="small" />
                )}
              </ListButton>
            </ListenQuestion>
            {/* { media &&} */}

            {/* <MediaContainer>
              <img
                src="https://www.w3schools.com/images/picture.jpg"
                alt="The Pulpit Rock"
                width="500"
                height="200"
              />
            </MediaContainer> */}
            <QuestionDescription size="14px">
              {currentQuestion.description}
            </QuestionDescription>
          </QuestionDescriptionContainer>
          <Options>
            <OptionLabel isChecked={answers[currentQuestion.id] === 'optionA'}>
              <input
                type="radio"
                name={`question-${currentQuestion.id}`}
                value="optionA"
                checked={answers[currentQuestion.id] === 'optionA'}
                onChange={handleOptionChange}
              />
              {currentQuestion.optionA}
            </OptionLabel>
            <OptionLabel isChecked={answers[currentQuestion.id] === 'optionB'}>
              <input
                type="radio"
                name={`question-${currentQuestion.id}`}
                value="optionB"
                checked={answers[currentQuestion.id] === 'optionB'}
                onChange={handleOptionChange}
              />
              {currentQuestion.optionB}
            </OptionLabel>
            <OptionLabel isChecked={answers[currentQuestion.id] === 'optionC'}>
              <input
                type="radio"
                name={`question-${currentQuestion.id}`}
                value="optionC"
                checked={answers[currentQuestion.id] === 'optionC'}
                onChange={handleOptionChange}
              />
              {currentQuestion.optionC}
            </OptionLabel>
            <OptionLabel isChecked={answers[currentQuestion.id] === 'optionD'}>
              <input
                type="radio"
                name={`question-${currentQuestion.id}`}
                value="optionD"
                checked={answers[currentQuestion.id] === 'optionD'}
                onChange={handleOptionChange}
              />
              {currentQuestion.optionD}
            </OptionLabel>
          </Options>
          <ButtonContainer>
            <Button
              disabled={currentQuestionIndex === 0}
              onClick={handlePrevious}
            >
              &larr; Anterior
            </Button>
            {currentQuestionIndex === quiz.questions.length - 1 ? (
              <Button
                disabled={!answers[currentQuestion.id]}
                onClick={handleSubmit}
              >
                Enviar{' '}
              </Button>
            ) : (
              <Button
                disabled={!answers[currentQuestion.id]}
                onClick={handleNext}
              >
                Próxima &rarr;{' '}
              </Button>
            )}
          </ButtonContainer>
        </>
      ) : (
        !seeQuestionsAnswers && (
          <StartQuizContainer>
            <Icon>
              <AssignmentTurnedInIcon />
            </Icon>
            <QuestionDescription>Quiz - {quiz.title}</QuestionDescription>
            {quiz.note && (
              <QuizScoreWrapper>
                <QuizScoreContainer>
                  <span>
                    Acertos:{' '}
                    {quiz.note !== 0
                      ? Math.round((quiz.note / 100) * quiz.questions.length)
                      : '0'}{' '}
                    de {quiz.questions.length} questões
                  </span>
                  <Score scorePointColor={quiz.note || 0}>({quiz.note}%)</Score>
                </QuizScoreContainer>
                <p title={String(quiz.doneAt)}>
                  Respondido em{' '}
                  {new Date(quiz.doneAt).toLocaleDateString('pt-BR')}
                </p>
              </QuizScoreWrapper>
            )}
            <Button onClick={() => handleStartQuiz()}>
              {getQuizButtonText()}
            </Button>
          </StartQuizContainer>
        )
      )}

      {seeQuestionsAnswers && (
        <QuestionsAnswers
          onBack={() => setSeeQuestionsAnswers(false)}
          questions={quiz.questions}
          studentAnswers={quiz.answers || {}}
        />
      )}
    </QuizContainer>
  );
};

const QuizScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  border-radius: 16px;
  border: 1px solid #374151;
  color: ${({ theme }) => theme.colors.themedTitle};
  gap: 2rem;

  p {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.text};
  }
`;

const ListenQuestion = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
`;

const ListButton = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  background: transparent;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }
`;

// const MediaContainer = styled.div`
//   display: none;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   img {
//     border-radius: 8px;
//   }
//   img:hover {
//     transform: scale(1.01);
//   }
// `;

const QuizScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
`;

const Score = styled.span<{ scorePointColor: number }>`
  color: ${({ scorePointColor, theme }) =>
    scorePointColor >= 40 ? theme.colors.primary : theme.colors.alert};
`;

const QuizAnimation = () => keyframes`
  0% { opacity: 0; color: transparent; transform: scale(1.02)}
  50% { color: auto }
  100% { opacity: 100%; transform: scale(1)}
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  border-radius: 18px;
  color: ${({ theme }) => theme.colors.themedTitle};
`;

const StartQuizContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  justify-content: center;
`;

const QuizContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  color: ${({ theme }) => theme.colors.text};
  padding: 20px;
  border-radius: 8px;
  width: 132rem;
  margin: 0 auto;
  text-align: center;
  animation: ${QuizAnimation} 0.5s ease-in-out;
  border-right: ${({ theme }) => `2px solid ${theme.colors.primary}`};
`;

const Progress = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  color: #aaa;
`;

const QuestionDescriptionContainer = styled.div`
  line-height: 1.5;
  margin-top: 0.5rem;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  overflow: hidden;
`;

const QuestionDescription = styled.h2<{ size?: string }>`
  font-size: ${({ theme, size }) => size || theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.themedTitle};
  margin: 0.5rem 0;
  word-break: break-word;
  white-space: normal;
  max-width: 100%;
  overflow-wrap: break-word;
  text-align: center;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionLabel = styled.label<{ isChecked?: boolean }>`
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.themedTitle};
  border: ${({ isChecked, theme }) =>
    isChecked ? `2px solid ${theme.colors.secondaryDark}` : 'none'};

  input {
    margin-right: 10px;
    height: 25px;
  }

  &:hover {
    filter: brightness(0.75);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 2rem;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 2.25rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    background-color: #646161;
    cursor: not-allowed;
  }
  transition: background-color 0.25s;
`;

export default QuizComponent;
