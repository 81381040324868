import React from 'react';
import SectionHeader from '../../../../components/SectionHeader';
import { CourseInfoComponent } from './styles';

interface CourseComponentProps {
  courseTitle: string;
  courseDescription: string;
}

const CourseInfo = ({
  courseTitle,
  courseDescription,
}: CourseComponentProps) => {
  return (
    <CourseInfoComponent>
      <SectionHeader>
        <h1>{courseTitle}</h1>
      </SectionHeader>
      <p>{courseDescription}</p>
    </CourseInfoComponent>
  );
};

export default CourseInfo;
