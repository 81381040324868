import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../../../services/api';

import './style.scss';

interface classComponentProps {
  classId: string;
}

interface ApiCourse {
  id: string;
  title: string;
  description: string;
  thumbnailUrl?: string;
}

interface ApiCourseData {
  course: ApiCourse[];
}

const ClassCourseList = ({ classId }: classComponentProps) => {
  const [courses, setCourse] = useState<ApiCourse[]>([]);

  useEffect(() => {
    async function requestCourse() {
      const { data } = await api.get<ApiCourse[]>(
        `/classes/${classId}/courses`,
      );

      setCourse(data);
    }
    requestCourse();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <section id="class-course-list" className="display-grid">
      {courses &&
        courses.map(course => (
          <Fragment key={course.id}>
            <Link
              className="course-card"
              to={{
                pathname: '/dashboard/attend-course',
                state: {
                  courseId: course.id,
                  courseName: course.title,
                  courseDescription: course.description,
                },
              }}
            >
              <div className="thumbnail-frame">
                <img
                  className="thumbnail"
                  src={
                    course.thumbnailUrl
                      ? course.thumbnailUrl
                      : 'https://via.placeholder.com/1920x1080/eee?text=Curso'
                  }
                  alt="Lesson video thumbnail"
                />
              </div>
              <h2 className="course-title typography-sans">{course.title}</h2>
              <p className="course-description typography-sans">
                {course.description}
              </p>
            </Link>
          </Fragment>
        ))}
    </section>
  );
};

export default ClassCourseList;
