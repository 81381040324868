import React, { MouseEventHandler } from 'react';
import { StrippedButtonContainer } from './StrippedButton.styles';

interface ComponentProps {
  className?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  children: React.ReactNode;
}

const StrippedButton = ({
  className = '',
  type = 'button',
  disabled = false,
  onClick = undefined,
  children,
}: ComponentProps) => (
  <StrippedButtonContainer
    className={className}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </StrippedButtonContainer>
);

export default StrippedButton;
