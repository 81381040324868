import React from 'react';
import styled, { keyframes } from 'styled-components';
import {
  MdNotifications,
  MdOutlineArrowDropDown,
  MdOutlineWbSunny,
  MdOutlineDarkMode,
} from 'react-icons/md';
import { Link } from 'react-router-dom';

import { useAvatarUrl } from '../../hooks/useAvatarUrl';
import { useHeader } from './hooks';
import Dropdown from './Dropdown';

const Header = () => {
  const { userAvatarUrl } = useAvatarUrl();
  const {
    score,
    linksData,
    isDarkMode,
    toogleTheme,
    isDropdownOpen,
    toogleDropdown,
    getDropdownItems,
  } = useHeader();

  return (
    <Container>
      <Wrapper>
        <LogoContainer>
          <Link to="/home">
            <img
              height={60}
              width={60}
              src={
                isDarkMode
                  ? '/assets/itus_redesign.svg'
                  : '/assets/itus-logo.svg'
              }
              alt="Logo"
            />
          </Link>
          <Title>Itus Hub</Title>
        </LogoContainer>
        <Links>
          {linksData.map(link => (
            <StyledLink key={link.name}>
              <Link to={link.path}>{link.name}</Link>
            </StyledLink>
          ))}
        </Links>
        <UserContainer>
          {score.balance && (
            <ScoreContainer>
              <ScoreLabel>
                <img
                  src="/assets/coin.svg"
                  alt="Ícone de moeda"
                  height={25}
                  width={25}
                />
                {score.balance || 0}
              </ScoreLabel>
              <ScoreLabel>
                <img
                  src="/assets/xp.svg"
                  alt="Ícone de experiência"
                  height={20}
                  width={20}
                />
                {score.experiencePoints || 0}
              </ScoreLabel>
            </ScoreContainer>
          )}
          <IconWrapper>
            <MdNotifications size={30} color="#fff" />
          </IconWrapper>
          <UserInfoWrapper
            className="user-info"
            isOpen={isDropdownOpen}
            onClick={toogleDropdown}
          >
            <Dropdown
              items={getDropdownItems()}
              isOpen={isDropdownOpen}
              toogleDropdown={toogleDropdown}
            />
            <Avatar
              src={userAvatarUrl}
              height={50}
              width={50}
              alt="Avatar do usuário"
              className="user-avatar user-info"
            />
            <IconDropdown className="user-info" isOpen={isDropdownOpen}>
              <MdOutlineArrowDropDown
                className="user-info"
                size={30}
                color="#fff"
              />
            </IconDropdown>
          </UserInfoWrapper>
          <IconWrapper onClick={toogleTheme}>
            {isDarkMode ? (
              <MdOutlineWbSunny size={30} color="#fff" />
            ) : (
              <MdOutlineDarkMode size={30} color="#fff" />
            )}
          </IconWrapper>
        </UserContainer>
      </Wrapper>
    </Container>
  );
};

const ScoreAnimation = () => keyframes`
  0% { opacity: 0; color: transparent; transform: scale(1.05)}
  50% { color: auto }
  100% { opacity: 100%; transform: scale(1)}
`;
const ScoreContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
`;
const ScoreLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  box-shadow: 0 0px 10px 0 #0000007f;
  background-color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-family: ${({ theme }) => theme.fonts.inter};
  animation: ${ScoreAnimation} 1.5s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: all 0.3s ease-in-out;
  }
`;
const Container = styled.nav`
  width: 100%;
  height: 11vh;
  position: fixed;

  background-color: ${({ theme }) => theme.colors.header};
  transition: all 0.3s ease-in-out;
  padding: 0 2rem;
  z-index: 99;
`;

const Wrapper = styled.div`
  max-width: 1120px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  animation: ${ScoreAnimation} 1.5s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.title};
  font-family: 'Inter', sans-serif;
  font-size: ${({ theme }) => theme.fontSizes.md};

  margin-left: 0.8rem;
  @media screen and (max-width: 920px) {
    display: none;
  }
`;

const Links = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  animation: ${ScoreAnimation} 1.5s ease-in-out;

  @media screen and (max-width: 920px) {
    display: none;
  }
`;

const StyledLink = styled.li`
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  border-radius: 0.5rem;

  & > a {
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;

    margin: 0 0.5rem;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.opacityIcon};
    }
    color: ${({ theme }) => theme.colors.title};
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const IconWrapper = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  animation: ${ScoreAnimation} 1.5s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityIcon};
  }
`;

const UserInfoWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 1rem;
  margin: 0 0.2rem;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.opacityIcon : 'transparent'};
  transition: all 0.3s ease-in-out;
  animation: ${ScoreAnimation} 1.5s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.opacityIcon};
  }
`;

const Avatar = styled.img`
  margin-left: 1rem;
`;

const IconDropdown = styled.div<{
  isOpen?: boolean;
}>`
  transition: all 0.3s ease-in-out;
  z-index: -1;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export default Header;
