import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Question } from '../../models/Quiz';
import useQuiz from '../../hooks/data/useQuiz';

type QuestionsAnswersProps = {
  studentAnswers: { [questionId: string]: string };
  questions: Question[];
  onBack: () => void;
};

const QuestionsAnswers = ({
  studentAnswers,
  questions,
  onBack,
}: QuestionsAnswersProps) => {
  const handleBack = () => {
    onBack();
  };

  const { checkQuestionsAnswers } = useQuiz();

  const [isAnswerCorrect, setIsAnswerCorrect] = useState<{
    [questionId: string]: boolean;
  }>({});

  useEffect(() => {
    const checkAnswers = async () => {
      const answers = await checkQuestionsAnswers(studentAnswers);
      setIsAnswerCorrect(answers);
    };

    checkAnswers();
  }, [studentAnswers, checkQuestionsAnswers]);

  return (
    <AnswersWrapperContainer>
      <h1>Respostas</h1>
      <AnswersWrapper>
        {questions.map((question: Question, index) => {
          const studentAnswerOption = studentAnswers[
            question.id
          ] as keyof Question;

          const studentAnswer = question[studentAnswerOption];
          const isCorrect = isAnswerCorrect[question.id] || false;

          return (
            <QuestionsAccordion key={question.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${question.id}-content`}
                id={`panel-${question.id}-header`}
              >
                <AccordionHeader isCorrect={isCorrect}>
                  <h4>
                    <CheckCircleOutlineIcon fontSize="small" />
                    {`Questão ${index + 1}`}
                  </h4>
                  <p>{question.description}</p>
                </AccordionHeader>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  <strong>Sua resposta:</strong>{' '}
                  {studentAnswer || 'Não respondida'}
                </p>
              </AccordionDetails>
            </QuestionsAccordion>
          );
        })}
      </AnswersWrapper>

      <Button type="button" onClick={handleBack}>
        Voltar
      </Button>
    </AnswersWrapperContainer>
  );
};

export default QuestionsAnswers;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.secondaryDark};
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 2.25rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    background-color: #646161;
    cursor: not-allowed;
  }
  transition: background-color 0.25s;
`;

const AnswersWrapperContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const AnswersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  line-height: normal;
  max-height: 12.5rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.text};
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    transition: all 0.5s ease-in-out;
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 6px;
    filter: brightness(0.1);
  }
`;

const QuestionsAccordion = styled(Accordion)`
  & .MuiAccordionSummary-root {
    background-color: ${({ theme }) => theme.colors.background};
    filter: brightness(1.2);
  }

  & .MuiAccordionDetails-root {
    background-color: ${({ theme }) => theme.colors.background};
    filter: brightness(1.1);
  }

  & .MuiAccordionDetails-root p {
    color: ${({ theme }) => theme.colors.themedTitle};
    font-size: ${({ theme }) => theme.fontSizes.xs};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
  & .MuiAccordionDetails-root p strong {
    color: ${({ theme }) => theme.colors.text};
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const AccordionHeader = styled.div<{ isCorrect: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.themedTitle};

  p {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    font-weight: bold;
  }
  h4 {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.825rem;
    color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.primary : '#c53030'};
  }

  & .MuiSvgIcon-root {
    color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.primary : '#c53030'};
  }
`;
