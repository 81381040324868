import React, { createContext, useMemo, useState } from 'react';
import { ThemeProvider as Provider } from 'styled-components';
import { darkTheme, lightTheme } from '../global/styles/theme';

export interface ThemeContextState {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ThemeContext = createContext<ThemeContextState>(
  {} as ThemeContextState,
);

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const providerValue = useMemo(
    () => ({ isDarkMode, setIsDarkMode }),
    [isDarkMode],
  );

  return (
    <ThemeContext.Provider value={providerValue}>
      <Provider theme={isDarkMode ? darkTheme : lightTheme}>
        {children}
      </Provider>
    </ThemeContext.Provider>
  );
};
