import { useCallback, useEffect, useState } from 'react';
import { CompletedLesson, UpdateLessonDTO } from '../../models/Lesson';
import api from '../../services/api';
import useToast from '../useToast';
import CourseVideo from '../../models/CourseVideo';

export default function useLessons(courseId: string | undefined) {
  const { addToast } = useToast();
  const [lessons, setLessons] = useState<CourseVideo[]>([]);
  const updateLesson = async (updateLessonDTO: UpdateLessonDTO) => {
    const { id, duration } = updateLessonDTO;
    await api.put(`/lessons/update`, { id, duration });
  };

  const checkIsLessonCompleted = useCallback(
    async (studentId: string, lessonId: string) => {
      const { data } = await api.get<CompletedLesson>(
        `/lessons/complete/${studentId}/${lessonId}`,
      );
      return data.isCompleted || false;
    },
    [],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const requestLessons = useCallback(async () => {
    const { data } = await api.get<CourseVideo[]>(
      `/courses/${courseId}/lessons`,
    );
    setLessons(data);
  }, [courseId]);

  const completeLesson = useCallback(
    async (studentId: string, lessonId: string) => {
      try {
        const { data } = await api.put<CompletedLesson>('lessons/complete', {
          studentId,
          lessonId,
        });
        if (data.isCompleted) {
          addToast({
            type: 'success',
            title: 'Aula concluída com sucesso!',
            description: `Parabéns, você recebeu ${data.balance} moedas e ${data.experiencePoints} pontos de experiência`,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (err: any) {
        if (err.response && err.response.data) {
          const { code } = err.response.data;
          switch (code) {
            case 'LESSON_ALREADY_COMPLETED':
              addToast({
                type: 'error',
                title: 'Aula já concluída!',
                description: 'Você não pode concluir novamente a aula',
              });
              break;
            case 'NOT_ENOUGH_WATCHED_TIME':
              addToast({
                type: 'error',
                title: 'Tempo insuficiente',
                description:
                  'Você não assistiu tempo suficiente para concluir a aula',
              });
              break;
            case 'NOT_WATCHED_LESSON':
              addToast({
                type: 'error',
                title: 'Aula não asistida',
                description:
                  'Você ainda não reproduziu a aula para poder concluí-la',
              });
              break;
            default:
              addToast({
                type: 'error',
                title: 'Erro ao concluir aula',
                description:
                  'Ocorreu um erro ao tentar concluir a aula, tente novamente',
              });
          }
        }
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    courseId && requestLessons();
  }, [courseId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    lessons,
    updateLesson,
    completeLesson,
    checkIsLessonCompleted,
  };
}
